<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4616_349"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.0117188" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4616_349)">
      <path
        d="M16 20.0117V13.0117H20V20.0117H16ZM10 20.0117V4.01172H14V20.0117H10ZM4 20.0117V9.01172H8V20.0117H4Z"
        
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconDailyJournal",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#4613E5" },
  },
};
</script>
