<template>
  <div
    class="modal fade"
    id="ActivoModal"
    ref="ActivoModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropPermissionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-0">
          <button
            ref="btnClose"
            type="button"
            class="border-0 bg_transparent p-0 position-absolute close_modal z-3"
            data-bs-dismiss="modal"
          >
            <i class="fa-solid fa-circle-xmark text-primary fs-2"></i>
          </button>
        </div>
        <div class="py-4 px-5">
          <h1 class="fs-5 text-primary-emphasis fw-bold mb-2">
            Selecciona un Activo
          </h1>
          <form @submit.prevent="crearActivo" class="">
            <div class="mb-3 text-start">
              <label
                for="exampleFormControlInput1"
                class="form-label fs-8 text-secondary"
                >Mercado</label
              >
              <select
                class="form-select rounded-1 fs-7"
                aria-label="Default select example"
                v-model="mercadoSelected"
                @change="mercadoSelectChange"
              >
                <option value="0" selected>Seleccione Mercado</option>

                <option
                  :value="mercado.idTipoActivo"
                  v-for="(mercado, index) in mercadoList"
                  :key="index"
                >
                  {{ mercado.nombre }}
                </option>
              </select>
            </div>
            <div class="mb-3 text-start">
              <label
                for="exampleFormControlInput1"
                class="form-label fs-8 text-secondary"
                >Activo</label
              >
              <select
                class="form-select rounded-1 fs-7"
                aria-label="Default select example"
                v-model="activoSelected"
                @change="mercadoSelectChange"
              >
                <option value="0" selected>Selecciona Activo</option>
                <option
                  :value="activo.idActivo"
                  v-for="(activo, index) in activoList"
                  :key="index"
                >
                  {{ activo.nombre }}
                </option>
              </select>
            </div>
            <div class="mt-4 text-end">
              <div
                class="btn btn-primary bg_dashboard rounded-5 px-4 border-0 me-3"
                data-bs-dismiss="modal"
              >
                <span class="fs-7 fw-bold text-primary-emphasis">cancelar</span>
              </div>
              <button type="submit" class="btn btn-primary rounded-5 px-4">
                <span class="fs-7 fw-bold">Agregar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ModalActivo",
  computed: {
    ...mapGetters("auth", ["getToken", "getUserId"]),
  },
  data() {
    return {
      mercadoList: [],
      activoList: [],
      mercadoSelected: 0,
      activoSelected: 0,
    };
  },
  mounted() {
    const myModalEl = document.getElementById('ActivoModal')
    myModalEl.addEventListener('hidden.bs.modal', event => {
      this.getActivoByUser({userId: this.getUserId, token: this.getToken});
    })
    axios
      .get(
        "https://api.es2trading.com/api/tipoActivo/",
        {
          headers: {
            Authorization: `Bearer ${this.getToken}`,
          },
        }
      )
      .then((response) => {
        this.mercadoList = response.data;
      })
      .catch((error) => {
        // Manejar el error
        console.error("Error al hacer la solicitud:", error);
      });
  },
  methods: {
    ...mapActions('watchlist', ['getActivoByUser']),
    crearActivo() {
      let data = {
        usuario: this.getUserId,
        idActivo: this.activoSelected,
      };
      const headers = {
        'Authorization': `Bearer ${this.getToken}`,
        'Content-Type': 'application/json'
      };
      axios.post('https://api.es2trading.com/api/seguimientoPar/', data, { headers })
      .then(response => {
        this.mercadoSelected = 0,
        this.activoSelected = 0,
        this.$refs.btnClose.click();
      })
      .catch(error => {
        // Manejar el error aquí
          console.error(error);
        });
    },
    setActivos() {},
    getActivosPorMercado(idMercado) {
      axios
        .get(
          `https://api.es2trading.com/api/activo/${idMercado}`,
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        )
        .then((response) => {
          // Manejar la respuesta de la API
          this.activoList = response.data;
        })
        .catch((error) => {
          // Manejar el error
          console.error("Error al hacer la solicitud:", error);
        });
    },
    mercadoSelectChange() {
      // console.log("Opción seleccionada:", this.mercadoSelected);
      this.activoList = [];
      this.getActivosPorMercado(this.mercadoSelected);
    },
    activoSelectChange() {
      // console.log("Opción seleccionada:", this.activoSelected);
    },
  },
};
</script>

<style></style>
