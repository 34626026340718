import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/auth/LoginView.vue";
import RegisterView from "../views/auth/RegisterView.vue";
import VerifyAccountView from "../views/auth/VerifyAccountView.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import DashboardHome from "../components/dashboard/home/DashboardHome.vue";
import RolesPermisos from "../components/dashboard/roles/RolesPermisos.vue";
import WatchList from "../components/dashboard/watchList/WatchList.vue";
import Bitacora from "../components/dashboard/bitacora/Bitacora.vue";

const routes = [
  // {Dashboard
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/verify-account",
    name: "verify account",
    component: VerifyAccountView,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'dashboard-home',
        component: DashboardHome,
      },
      {
        path: 'roles-permisos',
        name: 'Roles-permisos',
        component: RolesPermisos,
      },
      {
        path: 'watch-list',
        name: 'Lista de seguimiento',
        component: WatchList,
      },
      {
        path: 'bitacora',
        name: 'Bitacora de Traiding',
        component: Bitacora,
      },
      {
        path: 'daily-journal',
        name: 'Daily Journal',
        // component: Bitacora,
      },
      {
        path: 'trade-log',
        name: 'Trade Log',
        // component: Bitacora,
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
