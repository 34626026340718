<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4593_369"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4593_369)">
      <path
        d="M7 20V18H5V6H7V4H9V6H11V18H9V20H7ZM7 16H9V8H7V16ZM15 20V15H13V8H15V4H17V8H19V15H17V20H15ZM15 13H17V10H15V13Z"
        fill="#5F617A"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPrice",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#512ACD" },
  },
};
</script>
