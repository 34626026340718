<template>
  <section class="login-content">
    <div class="row m-0 align-items-center bg-white vh-100 position-relative">
      <div class="col-md-4 position-relative z2">
        <div class="row justify-content-center">
          <div class="col-9 px-0">
            <div class="row">
              <div class="col-12 mb-5">
                <div class="top-logo d-flex align-items-center">
                  <div class="me-3">
                    <logo></logo>
                  </div>
                  <span class="fw-bold fs-4">ES2Trading</span>
                </div>
              </div>
              <div class="col-12 mt-3 align-items-start d-flex flex-column">
                <h2 class="fs-2 fw-bold mb-2">¡Success!</h2>
                <p class="fs-7">Your account was successfully registered, check your email to confirm your account and return to login to enter our system</p>
                <router-link to="/" class="btn btn-primary rounded-5 px-4 mt-2">Back to login</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="logo_float position-absolute">
          <logo :width="300" :height="300" :opacity="0.08"></logo>
        </div>
      </div>
      <div class="col-md-8 d-md-block d-none bg-primary p-0 vh-100 overflow-hidden position-relative"
      >
        <img
          src="@/assets/images/auth/banner-1.jpg"
          class="img-fluid gradient-main"
          alt="images"
          loading="lazy"
        />
      </div>
      <div class="shape__login"></div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Logo from "@/components/global/Logo.vue";
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

export default {
  name: "VerifyAccountView",
  components: {
    Logo,
  },
  data() {
    return {
      showSpinner: false,
      poolData: {
        UserPoolId: 'us-east-2_8ozqVL1Vz',
        ClientId: 'ohl9npuhdbq2phva3vf0r5f8o'
      },
      formData: {
        username: '',
        password: ''
      },
      userPool: null,
      token: null
    }
  },
  mounted() {
    this.userPool = new CognitoUserPool(this.poolData);
    // console.log('load', this.userPool);
  },
  methods: {
    async validateUser(username, password) {
      const authenticationData = {
        Username: username,
        Password: password
      }
      const authenticationDetails = new AuthenticationDetails(authenticationData)
      const userData = {
        Username: username,
        Pool: this.userPool
      }
      const cognitoUser = new CognitoUser(userData)
      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')
      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) =>{
            //obtiene token del cognito user y se lo asigna a la variable token(this.token).
            this.token = result.getAccessToken().getJwtToken();
             resolve(true);
          },
          onFailure: (err) => reject(err)
        })
      })
    },
    async submitForm() {
      this.showSpinner = true
      try {
        const isValidUser = await this.validateUser(this.formData.username, this.formData.password)
        if (isValidUser) {
          
          this.showSpinner = false
          console.log('Token: ', this.token);
          // this.$router.push('/dashboard')
        } else {
          console.log('Usuario inválido')
        }
      } catch (error) { console.error('Error de validación de usuario:', error) }
    }
  }
};
</script>
<style lang="scss">
.logo_float{
  top: -150px;
  left: -100px;
}
</style>
