<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
$primary_ : #512ACD;
$primaryDark_ : #321775;
$success_ : #1AA053;
$danger_ : #C03221;
$text_dark : #1A1B2D;
.modal-backdrop{
  background-color: $primaryDark_ !important;
  &.show{
    opacity: 1 !important;
  }
}
.form-control:focus {
    box-shadow: none !important;
}
#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .text-primary{
    color: $primary_ !important;
  }
  .text-dark{
    color: $text_dark !important;
  }
  .bg-primary{
    background-color: $primary_ !important;
  }
  .btn-primary{
    background-color: $primary_ !important;
    border-color: $primary_ !important;
  }
  .bg_thead{
    background-color: rgba(8, 9, 56, 0.12);
  }
  .bg-success{
    background-color: $success_ !important;
  }
  .bg-danger{
    background-color: $danger_ !important;
  }
  .text-danger{
    color: $danger_ !important;
  }
  .bg_transparent{
    background-color: transparent !important;
  }
  .fw-semi-bold{
    font-weight: 500 !important;
  }
  .bg_dashboard{
    background-color: #f5f6fa !important;
  }
  .bg_dark{
    background-color: #020104 !important;
  }
  .bg_lightPink{
    background-color: #ece4fa !important;
  }
  .bg_primary_emphasis{
    background-color: #052c65 !important;
  }
  .texts-color{
    color: #2c3e50;
  }
  .text-secondary{
    color: #5F617A !important;
  }
  .text-primary-emphasis{
    color: $text_dark !important;
  }
  .separator{
     border-bottom: 1px solid #eee;
  }
  .border-danger-subtle{
    border-color: rgb(241 174 181 / 40%) !important;
  }
  .border-success-subtle{
    border-color: hwb(153deg 64% 19% / 40%) !important;
  }
  .border-secondary-subtle {
    border-color: hwb(206deg 77% 20% / 40%) !important;
  }
  .border-primary-subtle {
    border-color: rgb(158 197 254 / 40%) !important;
  }
  .color-danger-subtle{
    color: rgb(220 53 69 / 50%) !important;
  }
  .color-success-subtle{
    color: rgb(25 135 84 / 50%) !important;
  }
  .color-secondary-subtle {
    color: rgb(33 37 41 / 50%) !important;
  }
  .border-dashed{
    border-style: dashed !important;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.form-select:focus{
  box-shadow: none !important;
}
@import url('./app.scss');
</style>
