<template>
  <div class="d-flex justify-content-center pt-4 mb-2">
    <div class="col-10 d-flex justify-content-between top_status">
      <div class="d-flex flex-column align-items-start estrategia_name_st">
        <span class="fs-9 text-secondary opacity-75">Nombre Estrategia</span>
        <span class="fs-5 fw-bold text-primary-emphasis"> {{  estrategiaName  }} </span>
      </div>
      <div class="progress_st d-flex align-items-center">
        <span class="fs-8 text-secondary fs-bold pe-2">{{ count }}</span>
        <div
          class="progress col"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div class="progress-bar bg-primary" :style="{width: `${porcentaje}%`}"></div>
        </div>
        <span class="fs-8 text-secondary fs-bold ps-2">{{ lista_confirmanciones.length }}</span>
      </div>
    </div>
  </div>
  <div
    class="confirmacion_contenedor border border-bottom-0 border-left-0 border-right-0 px-5 pt-2 mt-3 d-flex justify-content-center border-opacity-10"
  >
    <div
      class="confirmacion_contenedor_item pt-4 d-flex flex-column align-items-center w-100"
      :class="{ 'd-none': confirmacion.isSaved }"
      v-for="(confirmacion, index) in lista_confirmanciones"
      :key="index"
      :style="{ zIndex: index + 1 }"
    >
      <div class="d-flex col-10">
        <div class="form-check text-start">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            :id="`confirmacion-${confirmacion.idConfirmacionEstrategia}`"
            v-model="confirmacion.cumplio"
            @change="checkboxChanged(confirmacion)"
          />
          <label
            class="form-check-label text-primary-emphasis fs-7 fw-bold"
            :for="`confirmacion-${confirmacion.idConfirmacionEstrategia}`"
          >
            Cumplio
          </label>
        </div>
        <div
          class="sep_line px-2 border border-top-0 border-bottom-0 border-start-0"
        ></div>
        <div
          class="info_confirmacion flex-fill d-flex"
          :class="{ 'opacity-50': !confirmacion.cumplio }"
        >
          <div class="col-8 text-start ps-3">
            <span class="fs-7 fw-bold text-secondary">{{
              confirmacion.name
            }}</span>
          </div>
          <div class="col text-end">
            <select
              class="form-select border-0 rounded-5 fs-8 text-primary-emphasis bg-primary-subtle"
              aria-label="Default select example"
              :disabled="!confirmacion.cumplio"
              v-model="confirmacion.idResultadoConfirmacion"
            >
              <option value="null" selected>-- Resultado --</option>
              <option
                :value="item.idResultadoConfirmacion"
                v-for="(item, index) in confirmacion.lista_resultados"
                :key="index"
              >
                {{ item.valor }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-10 mt-3" :class="{ 'opacity-50': !confirmacion.cumplio }" >
        <div class="px-1 d-flex flex-column justify-content-strech">
          <div
            class="position-relative w-100 h-100 bg_dashboard rounded-2 d-flex align-items-center justify-content-center border border-primary-subtle border-dashed"
            :class="{'py-5': imageSelected == null}"
          >
            <div class="opacity-0 position-absolute top-0 start-0 w-100 h-100 z-1">
              <input type="file" @change="getFileUpload($event, confirmacion)" class="d-block h-100 w-100" :class="{'cursor-pointer': confirmacion.cumplio}" :disabled="!confirmacion.cumplio">
            </div>
            <div  v-if="imageSelected == null" class="d-flex flex-column w-100 py-4">
              <i class="fa-solid fa-upload fs-5 text-primary-emphasis"></i>
              <span class="fs-8 fw-bold mt-2 text-primary-emphasis"
                >Upload image</span
              >
            </div>
            <div v-if="imageSelected != null" class="w-50 d-flex">
              <img :src="imageSelected" alt="" class="w-50">
              <div class="d-flex flex-column justify-content-end col ps-2 pb-2">
                <div class="d-flex flex-column align-items-start">
                  <span class="fs-9 text-primary fw-bold">Name:</span>
                  <span class="fs-8 text-primary-emphasis text-start">{{ archivoSeleccionado.name }}</span>
                </div>
                <div class="d-flex flex-column align-items-start">
                  <span class="fs-9 text-primary fw-bold">Type:</span>
                  <span class="fs-8 text-primary-emphasis">{{ archivoSeleccionado.type }}</span>
                </div>
                <div class="d-flex flex-column align-items-start">
                  <span class="fs-9 text-primary fw-bold">Size:</span>
                  <span class="fs-8 text-primary-emphasis">{{ archivoSeleccionado.size }} KB</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-10 d-flex justify-content-between">
        <div>
          <div v-if="showLoader">
            <div class="spinner-grow" style="width: 13px; height: 13px;" role="status"></div>
            <span class="ms-2 fs-9 text-secondary opacity-50">Saving Confirmation</span>
          </div>
        </div>
        <button
          class="btn btn-primary rounded-1 px-4"
          @click="agregarConfirmacionTrade(confirmacion)"
        >
          <span class="fs-7">Siguiente</span>
        </button>
      </div>
    </div>
    <div class="table_confirmaciones w-100 mt-3">
      <table class="table table-striped" v-if="this.confirmaciones_agregadas.length != 0">
        <thead class="">
            <tr>
              <th class="bg-primary-subtle" scope="col"><span class="fs-7 text-primary-emphasis fw-bold">Nombre</span></th>
              <th class="bg-primary-subtle" scope="col"><span class="fs-7 text-primary-emphasis fw-bold">Descripción</span></th>
              <th class="bg-primary-subtle" scope="col"><span class="fs-7 text-primary-emphasis fw-bold">Valor</span></th>
              <th class="bg-primary-subtle" scope="col"><span class="fs-7 text-primary-emphasis fw-bold">Cumplió</span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(confirmacion, index) in this.confirmaciones_agregadas" :key="index">
              <td class="fs-8">{{ confirmacion.nombre }}</td>
              <td class="fs-8">{{ confirmacion.desc }}</td>
              <td class="fs-8">{{ confirmacion.valor }}</td>
              <td class="fs-8">{{ (confirmacion.cumplio == '0')? 'NO':'SI' }}</td>
            </tr>
          </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ConfirmacionesTrade",
  props: {
    id_trade: {
      type: Number,
    },
  },
  data: () => {
    return {
      estrategiaName: '',
      estrategiaID: null,
      lista_confirmanciones: [],
      count:1,
      porcentaje:0,
      showLoader: false,
      confirmaciones_agregadas:[],
      archivoSeleccionado: null,
      imageSelected: null
    };
  },
  computed: {
    ...mapGetters("auth", ["getToken", "getUserId"]),
    ...mapGetters("watchlist", [
      "listadeActivosPorUsuario",
      "listadeEstrategiaPorUsuario",
    ]),
    ...mapGetters("bitacora", ["listaTipodeOperacion", "listaTrades"])
  },
  async mounted() {
    const myModalEl = document.getElementById("tradingModal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      // this.confirmaciones_agregadas = [],
      // this.archivoSeleccionado = null
    });
    if (this.id_trade != null) {
      let response_ = await this.getTradesPorIdTrade({
        tradeId: this.id_trade,
        token: this.getToken,
      });
      this.estrategiaID = response_.data.estrategia.idEstrategia;
      let prevDat = await this.getConfirmacionByIdEstrategia({
        estrategiaId: this.estrategiaID,
        token: this.getToken,
      });
      this.estrategiaName = prevDat.data[0].estrategia.nombre;
      prevDat.data.map((e, index) => {
        let item_ = {
          id: index,
          name: e.nombre,
          desc: e.descripcion,
          idConfirmacionEstrategia: e.idConfirmacionEstrategia,
          cumplio: false,
          idResultadoConfirmacion: null,
          isSaved: false,
          image: null
        };
        this.lista_confirmanciones.push(item_);
      });
      this.lista_confirmanciones.reverse();
      this.porcentajeAdd();
      this.lista_confirmanciones.map((c) => {
        c.lista_resultados = [];
        this.getResultadosByConfirmacionID({
          token: this.getToken,
          confirmacionId: c.idConfirmacionEstrategia,
        }).then((response) => {
          response.data.map((r) => {
            c.lista_resultados.push({
              valor: r.valor,
              idResultadoConfirmacion: r.idResultadoConfirmacion,
              idConfirmacion:
                r.confirmacionesEstrategia.idConfirmacionEstrategia,
            });
          });
        });
        
        console.log("carga modal confirmacion", this.lista_confirmanciones);
      });
    }
  },
  methods: {
    getFileUpload(event, el){
      this.archivoSeleccionado = event.target.files[0];
      if(this.archivoSeleccionado){
        const reader = new FileReader();
        reader.readAsDataURL(this.archivoSeleccionado);
        reader.onload = (e) => {
          this.imageSelected = e.target.result;
        };
      }
      console.log('subida de imagen', this.archivoSeleccionado);

    },
    porcentajeAdd(){
      this.porcentaje = (this.count / this.lista_confirmanciones.length) * 100;
    },
    ...mapActions("watchlist", [
      "getConfirmacionByIdEstrategia",
      "getResultadosByConfirmacionID",
    ]),
    ...mapActions("bitacora", ["getTradePorUsuario", "getTradesPorIdTrade","getConfirmacionTradesPorIdTrade"]),
    checkboxChanged(el) {
      if (!el.cumplio) el.idResultadoConfirmacion = null;
    },
    async agregarConfirmacionTrade(item) {
      const formData = new FormData();
      this.showLoader = true;
      item.id_trade = this.id_trade;
      let valueCumplio = "0";
      let id_resultado_confirmacion = 0;
      !item.cumplio ? (valueCumplio = "0") : (valueCumplio = "1");
      
      item.idResultadoConfirmacion == null
        ? (id_resultado_confirmacion = item.lista_resultados[0].idResultadoConfirmacion)
        : (id_resultado_confirmacion = item.idResultadoConfirmacion);
      let data_ = {
        idTrade: this.id_trade,
        cumplio: valueCumplio,
        idResultadoConfirmacion: id_resultado_confirmacion,
        usuario: this.getUserId,
      };
      console.log('datos a enviar',this.archivoSeleccionado, data_);
      formData.append('imageFile', this.archivoSeleccionado);
      formData.append('confirmacionesTradeData', JSON.stringify(data_));
      const headers = {
        Authorization: `Bearer ${this.getToken}`,
        'Content-Type': 'multipart/form-data'
      };
      await axios.post("https://api.es2trading.com/api/confirmacionestrade/", formData, { headers })
        .then((response) => {
          item.isSaved = true;
          if(this.count< this.lista_confirmanciones.length) this.count ++
          this.porcentajeAdd();
          this.showLoader = false;
          this.archivoSeleccionado= null,
          this.imageSelected= null
          // console.log("agregar confirmacion", response);
        })
        .catch((error) => {
          // Manejar el error aquí
          console.error(error);
        });
        if((item.id + 1) == this.lista_confirmanciones.length){
          // console.log('carga tabla', item.id, this.lista_confirmanciones.length);
          let responseConfirmacion = await this.getConfirmacionTradesPorIdTrade({tradeId: this.id_trade, token: this.getToken});
          // this.$refs.closeModalTrading.click();
          responseConfirmacion.data.map((c)=>{
            let values_ = {
              nombre: c.nombreConfirmacionEstrategia,
              desc: c.descripcionConfirmacionEstrategia,
              valor: c.valorResultadoConfirmacion,
              cumplio: c.cumplioConfirmacionTrade,
              urlArchivo: c.urlArchivo,
            }

            this.confirmaciones_agregadas.push(values_);
          });
          // console.log('.........................', responseConfirmacion.data);
          // console.log('despues de agregar', this.confirmaciones_agregadas);
        }

    },
  },
};
</script>

<style lang="scss">
.confirmacion_contenedor {
  position: relative;
  min-height: 340px;
  .confirmacion_contenedor_item {
    position: absolute;
    background-color: #fff;
  }
}
.progress_st{
  width: 200px;
  .progress{
    height: 5px !important;
  }
}
</style>
