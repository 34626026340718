<template>
  <section class="login-content">
    <div class="row m-0 align-items-center bg-white vh-100 position-relative">
      <div class="col-md-4 position-relative z2">
        <div class="row justify-content-center">
          <div class="col-9 px-0">
            <div class="row">
              <div class="col-12 mb-5">
                <div class="top-logo d-flex align-items-center">
                  <div class="me-3">
                    <logo></logo>
                  </div>
                  <logo-name :width="132" :fill="'#0A031C'" class="pt-1"></logo-name>
                </div>
              </div>
              <div class="col-12">
                <h2 class="fs-2 fw-bold mb-2">Sign In</h2>
                <p class="fs-7">Login to stay connected.</p>
              </div>
              <div class="col-12">
                <form @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <div class="form-group text-start">
                        <label
                          for="email"
                          class="form-label fs-8 text-body-tertiary"
                          >Email</label
                        >
                        <input
                          type="text"
                          class="form-control rounded-1"
                          id="email"
                          aria-describedby="email"
                          placeholder=" "
                          v-model="formData.username"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 mb-3">
                      <div class="form-group text-start">
                        <label
                          for="password"
                          class="form-label fs-8 text-body-tertiary"
                          >Password</label
                        >
                        <input
                          type="password"
                          class="form-control rounded-1"
                          id="password"
                          aria-describedby="password"
                          placeholder=" "
                          v-model="formData.password"
                        />
                      </div>
                    </div>
                    <div
                      class="col-lg-12 d-flex justify-content-between align-items-center mb-4"
                    >
                      <div class="form-check ps-0">
                        <input
                          type="checkbox"
                          class="form-check-input fs-8 pt-0 mt-2 ps-0 ms-0 me-1"
                          id="customCheck1"
                        />
                        <label class="form-check-label fs-8" for="customCheck1"
                          >Remember Me</label
                        >
                      </div>
                      <a href="/auth/reset-password" class="fs-8"
                        >Forgot Password?</a
                      >
                    </div>
                    <div class="col-12">
                      <div class="d-flex justify-content-center">
                        <button
                          type="submit"
                          class="btn btn-primary rounded-1 px-4"
                        >
                          Sign In
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                            v-if="showSpinner"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="col-12">
                      <p class="mt-3 text-center fs-7">
                        <span class="pe-1">Don’t have an account?</span>
                        <a href="/register" class="text-underline fs-7"
                          >Click here to sign up.</a
                        >
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="logo_float position-absolute">
          <logo :width="300" :height="300" :opacity="0.08"></logo>
        </div>
      </div>
      <div
        class="col-md-8 d-md-block d-none bg-primary p-0 vh-100 overflow-hidden position-relative"
      >
        <img
          src="@/assets/images/auth/banner-1.jpg"
          class="img-fluid gradient-main"
          alt="images"
          loading="lazy"
        />
      </div>
      <div class="shape__login"></div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Logo from "@/components/global/Logo.vue";
import LogoName from "@/components/global/LogoName.vue";
import { mapGetters, mapActions } from 'vuex';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

export default {
  name: "LoginView",
  components: {
    Logo,
    LogoName
  },
  computed: {
    ...mapGetters('auth', ['getToken','getUserId'])
  },
  data() {
    return {
      showSpinner: false,
      poolData: {
        UserPoolId: "us-east-2_dIvLCE7eX",
        ClientId: "19musn4gq65rjutoo2qtb0umb6",
      },
      formData: {
        username: "",
        password: "",
      },
      userPool: null,
      token: null,
    };
  },
  
  mounted() {
    this.userPool = new CognitoUserPool(this.poolData);
    // console.log('load user data from cognito', this.userPool);
  },
  methods: {
    ...mapActions('auth', ['setToken','setUserId', 'setUserName', 'setUserLastName', 'setUserEmail']),
    async validateUser(username, password) {
      const authenticationData = {
        Username: username,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const userData = {
        Username: username,
        Pool: this.userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            //obtiene token del cognito user y se lo asigna a la variable token(this.token).
            let userId = result.idToken.payload['cognito:username'];
            let userName = result.idToken.payload['custom:firstname'];
            let userLastName = result.idToken.payload['custom:lastname'];
            let userEmail = result.idToken.payload['email'];
            // console.log('cognito', userLastName);
            this.token = result.getAccessToken().getJwtToken();
            this.setToken(this.token);
            this.setUserId(userId);
            this.setUserName(userName);
            this.setUserLastName(userLastName);
            this.setUserEmail(userEmail);
            resolve(true);
          },
          onFailure: (err) => reject(err),
        });
      });
    },
    async submitForm() {
      this.showSpinner = true;
      try {
        const isValidUser = await this.validateUser(
          this.formData.username,
          this.formData.password
        );
        if (isValidUser) {
          this.showSpinner = false;
          // console.log("Token: ", this.getToken);
          this.$router.push('/dashboard')
        } else {
          console.log("Usuario inválido");
        }
      } catch (error) {
        console.error("Error de validación de usuario:", error);
      }
    },
  },
};
</script>
<style lang="scss">
.logo_float {
  top: -150px;
  left: -100px;
}
</style>
