<template>
  <div class="dashboard-layout position-absolute w-100 h-100">
    <div class="d-flex h-100">
      <div class="sidebar-st p-0 position-relative">
        <sidebar />
      </div>
      <div class="col p-0 bg_dashboard st_dashboard">
        <div class="navBarTemplate mb-5">
         <navbar/>
        </div>
        <div class="main-site">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/base/Sidebar.vue";
import Navbar from "@/components/base/Navbar.vue";
export default {
  name: "Dashboard",
  components: { Sidebar, Navbar },
};
</script>

<style lang="scss">
  .sidebar-st{
    width: 257px;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.01);
  }
</style>
