<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4614_5059"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.0117188" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4614_5059)">
      <path
        d="M6.75 22.0125C6 22.0125 5.35417 21.7583 4.8125 21.25C4.27083 20.7417 4 20.1125 4 19.3625V5.4125C4 4.77917 4.19583 4.2125 4.5875 3.7125C4.97917 3.2125 5.49167 2.89583 6.125 2.7625L16 0.8125V16.8125L6.525 18.7125C6.375 18.7458 6.25 18.825 6.15 18.95C6.05 19.075 6 19.2125 6 19.3625C6 19.5458 6.075 19.7 6.225 19.825C6.375 19.95 6.55 20.0125 6.75 20.0125H18V4.0125H20V22.0125H6.75ZM9 16.1875L14 15.2125V3.2625L9 4.2375V16.1875ZM7 16.5875V4.6375L6.625 4.7125C6.44167 4.74583 6.29167 4.825 6.175 4.95C6.05833 5.075 6 5.22917 6 5.4125V16.8375C6.08333 16.8042 6.17083 16.775 6.2625 16.75C6.35417 16.725 6.44167 16.7042 6.525 16.6875L7 16.5875Z"
        
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBitacora",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#4613E5" },
  },
};
</script>
