import axios from "axios";
const apiPost = {
  async addEstrategia(data) {
    let header = data.headers
    let body = data.data
    console.log('post.....', header);
    return axios.post('https://api.es2trading.com/api/estrategia/', data.body, { header })
    }
}
export default apiPost;
