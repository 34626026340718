<template>
  <section class="register-content">
    <div class="row m-0 align-items-center bg-white h-100 position-relative">
      <div
        class="col-md-6 d-md-block d-none bg-primary p-0 vh-100 overflow-hidden"
      >
        <img
          src="@/assets/images/auth/banner-11.jpg"
          class="img-fluid gradient-main"
          alt="images"
          loading="lazy"
        />
      </div>
      <div class="col-md-6 position-relative z2">
        <div class="row justify-content-center">
          <div class="col-9 px-0">
            <div class="row">
              <div class="col-12 mb-5">
                <div class="top-logo d-flex align-items-center">
                  <div class="me-3">
                    <logo></logo>
                  </div>
                  <span class="fw-bold fs-4">ES2Trading</span>
                </div>
              </div>
              <div class="col-12">
                <h2 class="fs-2 fw-bold mb-2">Register</h2>
                <p class="fs-7">Create your ES2Trading account.</p>
              </div>
              <div class="register_steps d-flex justify-content-center">
                <div
                  class="w-25 position-relative d-flex justify-content-between mb-3"
                >
                  <button
                    class="rounded-circle"
                    :class="{
                      active:
                        formData.name !== '' &&
                        formData.lastName !== '',
                      'bg-complete': step == 2,
                    }"
                    @click="step = 1"
                  >
                    <span class="fw-bold fs-8"> 1 </span>
                  </button>
                  <div class="line_ position-absolute">
                    <div class="fondo_" :class="{ 'w-100': step == 2 }"></div>
                  </div>
                  <button
                    class="rounded-circle"
                    :class="{
                      active:
                        formData.username !== '' &&
                        formData.password !== '' &&
                        step == 2,
                      'bg-complete':
                        formData.password === formData.confirm_password &&
                        step == 2,
                    }"
                  >
                    <span class="fw-bold fs-8">2</span>
                  </button>
                </div>
              </div>
              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="col-11">
                    <form @submit.prevent="registroUsuario" ref="formularioRegistro">
                      <div class="d-flex justify-content-center w-100">
                        <div class="step_1 w-75" v-if="step == 1">
                          <div class="row">
                            <div class="col-12">
                              <div class="form-group text-start mb-3">
                                <label
                                  for="full-name"
                                  class="form-label fs-8 text-body-tertiary"
                                  >First Name</label
                                >
                                <input
                                  type="text"
                                  class="form-control rounded-0"
                                  id="full-name"
                                  placeholder=" "
                                  v-model="formData.name"
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group text-start mb-3">
                                <label
                                  for="last-name"
                                  class="form-label fs-8 text-body-tertiary"
                                  >Last Name</label
                                >
                                <input
                                  type="text"
                                  class="form-control rounded-0"
                                  id="last-name"
                                  placeholder=" "
                                  v-model="formData.lastName"
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group text-start mb-4">
                                <label
                                  for="email"
                                  class="form-label fs-8 text-body-tertiary"
                                  >Email</label
                                >
                                <input
                                  type="email"
                                  class="form-control rounded-0"
                                  id="email"
                                  placeholder=" "
                                  v-model="formData.email"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="step_2 w-75" v-if="step == 2">
                          <div class="row" >
                            <div class="col-12" v-if="false">
                              <div class="form-group text-start mb-3">
                                <label
                                  for="phone"
                                  class="form-label form-label fs-8 text-body-tertiary"
                                  >User Name</label
                                >
                                <input
                                  type="text"
                                  class="form-control rounded-0"
                                  id="userName"
                                  placeholder=" "
                                  v-model="formData.username"
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group text-start mb-3">
                                <label
                                  for="password"
                                  class="form-label form-label fs-8 text-body-tertiary"
                                  >Password</label
                                >
                                <input
                                  type="password"
                                  class="form-control rounded-0"
                                  id="password"
                                  placeholder=" "
                                  v-model="formData.password"
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group text-start mb-3">
                                <label
                                  for="confirm-password"
                                  class="form-label form-label fs-8 text-body-tertiary"
                                  >Confirm Password</label
                                >
                                <input
                                  type="password"
                                  class="form-control rounded-0"
                                  id="confirm-password"
                                  placeholder=" "
                                  v-model="formData.confirm_password"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          v-if="step == 2"
                          class="col-lg-12 d-flex justify-content-center"
                        >
                          <div class="form-check mb-3">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="customCheck1"
                            />
                            <label
                              class="form-check-label fs-7"
                              for="customCheck1"
                              >I agree with the terms of use</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span
                          type="submit"
                          class="d-block btn btn-primary rounded-5 px-4"
                          v-if="step == 1"
                          @click="nextStep()"
                          :class="{
                            disabled:
                              formData.name.firstname == '' ||
                              formData.name.lastname == '' ||
                              formData.name.email == '',
                          }"
                          >Next</span
                        >
                        <button
                          type="submit"
                          class="btn btn-primary rounded-5 px-4"
                          v-if="step == 2"
                          :class="{
                            disabled:
                              formData.password !== formData.confirm_password,
                          }"
                        >
                          Register
                          <div class="spinner-border spinner-border-sm" role="status" v-if="showSpinner">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      </div>
                      <!-- <p class="text-center my-3">or sign in with other accounts?</p>
                  <div class="d-flex justify-content-center">
                    <ul class="list-group list-group-horizontal list-group-flush">
                      <li class="list-group-item border-0 pb-0">
                        <a href="#"><img src="@/assets/images/brands/fb.svg" alt="fb" loading="lazy" /></a>
                      </li>
                      <li class="list-group-item border-0 pb-0">
                        <a href="#"><img src="@/assets/images/brands/gm.svg" alt="gm" loading="lazy" /></a>
                      </li>
                      <li class="list-group-item border-0 pb-0">
                        <a href="#"><img src="@/assets/images/brands/im.svg" alt="im" loading="lazy" /></a>
                      </li>
                      <li class="list-group-item border-0 pb-0">
                        <a href="#"><img src="@/assets/images/brands/li.svg" alt="li" loading="lazy" /></a>
                      </li>
                    </ul>
                  </div> -->
                      <p class="mt-3 text-center fs-7">
                        Already have an Account
                        <a href="/" class="text-underline">Sign In</a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logo_float position-fixed">
          <logo :width="300" :height="300" :opacity="0.08"></logo>
        </div>
      </div>
      <div class="shape__register"></div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Logo from "@/components/global/Logo.vue";
import AWS from 'aws-sdk';

export default {
  name: "RegisterView",
  components: {
    Logo,
  },
  data() {
    return {
      cognitoService: null,
      showSpinner: false,
      formData: {
        email: "",
        username: "",
        password: "",
        confirm_password: "",
        name:"",
        lastName: "",
      },
      step: 1,
      poolData: {
        UserPoolId: "us-east-2_dIvLCE7eX",
        region: "us-east-2",
        ClientId: "19musn4gq65rjutoo2qtb0umb6",
      },
    };
  },
  mounted() {
    this.initCognitoConfig();
  },
  methods: {
    async registroUsuario(){
      try {
        const params = {
          ClientId: this.poolData.ClientId,
          Username: this.formData.email,
          Password: this.formData.password,
          UserAttributes: [
            { Name: 'custom:firstname', Value: this.formData.name },
            { Name: 'custom:lastname', Value: this.formData.lastName },
            { Name: 'custom:idrol', Value: '0' }
          ],
        };
        const data = await this.cognitoService.signUp(params).promise();
        this.$router.push("/verify-account");
      } catch (error) {
        console.error('Error al registrar usuario:', error);        
      }
    },
    initCognitoConfig(){
      AWS.config.update({
        region: this.poolData.region,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.poolData.UserPoolId,
        }),
      });
      this.cognitoService = new AWS.CognitoIdentityServiceProvider();
    },
    validateEmail(e) {
      const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regexCorreo.test(e);
    },
    nextStep() {
      let validEmail = this.validateEmail(this.formData.email);
      if (validEmail) this.step = 2;
      if (!validEmail) this.$refs.formularioRegistro.reportValidity();
      if (this.formData.email == "")
        this.$refs.formularioRegistro.reportValidity();
    },
  },
};
</script>
<style lang="scss">
.register-content {
  .logo_float {
    top: 0;
    right: -100%;
  }
  .register_steps {
    .active {
      border-color: #3a57e8 !important;
    }
    button {
      width: 30px;
      height: 30px;
      display: block;
      background-color: transparent;
      border: 2px #ddd solid;
      font-size: 10px;
    }
    .line_ {
      width: calc(100% - 60px);
      height: 4px;
      left: 30px;
      background-color: #ddd;
      top: 50%;
      margin-top: -2px;
      .fondo_ {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #3a57e8;
        height: 100%;
        width: 0;
        transition: width 0.5s ease 0s;
      }
    }
    .bg-complete {
      background-color: #3a57e8 !important;
      color: #fff !important;
      font-weight: 600;
    }
  }
}
</style>
