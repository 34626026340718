<template>
  <div
    class="modal fade"
    id="changePasswordModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropPermissionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-0">
          <!-- <button
            type="button"
            class="border-0 bg_transparent p-0 position-absolute close_modal z-3"
            data-bs-dismiss="modal"
          >
            <i class="fa-solid fa-circle-xmark text-primary fs-2"></i>
          </button> -->
          <div
            class="px-5 pt-3 pb-3 d-flex flex-column align-items-center bg-white col-10 mx-auto"
          >
            <div class="text-center mb-3">
              <span class="text-dark fs-4 fw-bold">Change Password</span>
            </div>
            <div class="form_body w-100">
              <div class="d-flex mb-4">
                <div class="col-12 px-1 d-flex flex-column align-items-start">
                  <span class="fs-6 text-dark mb-1 fs-semi-bold"
                    >Password Actual</span
                  >
                  <div
                    class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                  >
                    <div class="ico_input">
                      <icon-user :fill="'#2c3e50'"></icon-user>
                    </div>

                    <div
                      class="input_st w-100 d-flex align-items-center pe-0"
                      :class="{ 'opacity-75': disable_inputs }"
                    >
                      <input
                        :type="!showCurrent ? 'password' : 'text'"
                        class="form-control border-0 fs-7 d-block"
                        v-model="current_password"
                        :disabled="disable_inputs"
                      />
                      <span @click="showCurrent = !showCurrent" class="cursor-pointer">
                        <i class="fa-regular" :class="{'fa-eye': !showCurrent,'fa-eye-slash': showCurrent}"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="col-12 px-1 d-flex flex-column align-items-start">
                  <span class="fs-6 text-dark mb-1 fs-semi-bold"
                    >Nuevo password</span
                  >
                  <div
                    class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                  >
                    <div class="ico_input">
                      <icon-user :fill="'#2c3e50'"></icon-user>
                    </div>

                    <div
                      class="input_st w-100 d-flex align-items-center"
                      :class="{ 'opacity-75': disable_inputs }"
                    >
                      <input
                      :type="!showNew ? 'password' : 'text'"
                        class="form-control border-0 fs-7 d-block"
                        v-model="new_password"
                        :disabled="disable_inputs"
                      />
                      <span @click="showNew = !showNew" class="cursor-pointer">
                        <i class="fa-regular" :class="{'fa-eye': !showNew,'fa-eye-slash': showNew}"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="col-12 px-1 d-flex flex-column align-items-start">
                  <span class="fs-6 text-dark mb-1 fs-semi-bold"
                    >Confirmar nuevo password</span
                  >
                  <div
                    class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                  >
                    <div class="ico_input">
                      <icon-user :fill="'#2c3e50'"></icon-user>
                    </div>

                    <div
                      class="input_st w-100 d-flex align-items-center"
                      :class="{ 'opacity-75': disable_inputs }"
                    >
                      <input
                      :type="!showConfirm ? 'password' : 'text'"
                        class="form-control border-0 fs-7 d-block"
                        v-model="confirm_password"
                        :disabled="disable_inputs"
                      />
                      <span @click="showConfirm = !showConfirm" class="cursor-pointer">
                        <i class="fa-regular" :class="{'fa-eye': !showConfirm,'fa-eye-slash': showConfirm}"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-start mb-2">
                  <div v-if="loading">
                    <div
                      class="spinner-grow"
                      style="width: 13px; height: 13px"
                      role="status"
                    ></div>
                    <span class="ms-2 fs-9 text-secondary opacity-50"
                      >Actualizando contraseña</span
                    >
                  </div>
                  <div v-if="success_" class="d-flex align-items-center">
                    <span
                      ><i class="fa-regular fa-circle-check text-success"></i
                    ></span>
                    <span class="ms-2 fs-9 text-success"
                      >Contraseña Actualizada Correctamente</span
                    >
                  </div>
                  <div v-if="error_" class="d-flex align-items-center">
                    <span
                      ><i
                        class="fa-solid fa-circle-exclamation opacity-75 text-danger"
                      ></i
                    ></span>
                    <span class="ms-2 fs-9 text-danger opacity-75 text-start">
                      {{ errorMessage }}</span
                    >
                  </div>
                </div>
                <div class="text-end">
                  <div
                    class="btn btn-primary bg_dashboard rounded-5 px-4 border-0 me-3"
                    data-bs-dismiss="modal"
                  >
                    <span class="fs-7 fw-bold text-primary-emphasis"
                      >Cerrar</span
                    >
                  </div>
                  <div
                    class="btn btn-primary rounded-5 px-4"
                    @click="changePassword()"
                  >
                    <span class="fs-7 fw-bold">Cambiar</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth, Amplify } from "aws-amplify";
import IconUser from "@/components/icons/IconUser.vue";
export default {
  name: "ModalChangePassword",
  data() {
    return {
      new_password: "",
      current_password: "",
      confirm_password: "",
      disable_inputs: false,
      errorMessage: "Las contraseñas deben ser iguales",
      loading: false,
      success_: false,
      error_: false,
      showNew: false,
      showCurrent: false,
      showConfirm: false,
    };
  },
  components: { IconUser },
  computed: {},
  mounted() {
    const myModalEl = document.getElementById("changePasswordModal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      this.loading = false;
      this.success_ = false;
      this.error_ = false;
      this.disable_inputs = false;
      this.new_password = "";
      this.confirm_password = "";
      this.current_password = "";
    });
  },
  created() {
    const awsConfig = {
      Auth: {
        region: "us-east-2",
        userPoolId: "us-east-2_dIvLCE7eX",
        userPoolWebClientId: "19musn4gq65rjutoo2qtb0umb6",
      },
    };
    Amplify.configure(awsConfig);
  },
  methods: {
    async changePassword() {
      if (this.new_password != this.confirm_password) {
        this.error_ = true;
        setTimeout(() => {
          this.error_ = false;
        }, 2000);
        return;
      }
      if (
        (this.new_password == "") &
        (this.confirm_password == "") &
        (this.current_password == "")
      ) {
        this.error_ = true;
        this.errorMessage = "Debe llenar todos los campos";
        setTimeout(() => {
          this.error_ = false;
        }, 3000);
        return;
      }
      try {
        this.loading = true;
        const user = await Auth.currentAuthenticatedUser();
        this.disable_inputs = true;
        await Auth.changePassword(
          user,
          this.current_password,
          this.new_password
        )
          .then((res) => {
            this.loading = false;
            this.success_ = true;
            this.disable_inputs = false;
            this.new_password = "";
            this.confirm_password = "";
            this.current_password = "";
            setTimeout(() => {
              this.success_ = false;
            }, 3000);
            console.log("paso", res);
          })
          .catch((error) => {
            this.loading = false;
            this.error_ = true;
            this.errorMessage = error.message;
            setTimeout(() => {
              this.error_ = false;
            }, 3000);
          });
        // alert("Contraseña cambiada con éxito");
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.form-control:disabled {
  background-color: #fff !important;
}
</style>
