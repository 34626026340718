<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4614_5049"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.0117188" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4614_5049)">
      <path
        d="M5.55 19.0125L2 15.4625L3.4 14.0625L5.525 16.1875L9.775 11.9375L11.175 13.3625L5.55 19.0125ZM5.55 11.0125L2 7.4625L3.4 6.0625L5.525 8.1875L9.775 3.9375L11.175 5.3625L5.55 11.0125ZM13 17.0125V15.0125H22V17.0125H13ZM13 9.0125V7.0125H22V9.0125H13Z"
        
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconWatchList",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#4613E5" },
  },
};
</script>
