<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card rounded-1 border-0 shadow-sm p-3">
          <div class="info-box d-flex justify-content-between">
            <div class="d-flex">
              <div class="info-box_bar bg-success bg-opacity-50 me-3"></div>
              <div class="info-box_data d-flex flex-column align-items-start">
                <span class="fs-8 fw-bold text-secondary">Factor de Beneficio</span>
                <span class="fs-5 fw-bold text-primary-emphasis">0.00</span>
              </div>
            </div>
            <div>
              <div class="info-box_ico rounded-2 d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-arrow-up-right-dots fs-6 text-primary-emphasis"></i>
              </div>
            </div>
          </div>
          <div class="number-box d-flex mt-2">
            <div class="fs-8 fw-bold text-success text-opacity-50"><i class="fa-solid fa-up-long fs-9"></i> <span class="fs-8">+26%</span></div>
            <span class="fs-8 text-secondary text-opacity-50 ms-2">this week</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card rounded-1 border-0 shadow-sm p-3">
          <div class="info-box d-flex justify-content-between">
            <div class="d-flex">
              <div class="info-box_bar bg-danger bg-opacity-50 me-3"></div>
              <div class="info-box_data d-flex flex-column align-items-start">
                <span class="fs-8 fw-bold text-secondary">Factor de Beneficio</span>
                <span class="fs-5 fw-bold text-primary-emphasis">0.00</span>
              </div>
            </div>
            <div>
              <div class="info-box_ico rounded-2 d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-arrow-up-right-dots fs-6 text-primary-emphasis"></i>
              </div>
            </div>
          </div>
          <div class="number-box d-flex mt-2">
            <div class="fs-8 fw-bold text-danger text-opacity-50"><i class="fa-solid fa-down-long fs-9"></i> <span class="fs-8">+26%</span></div>
            <span class="fs-8 text-secondary text-opacity-50 ms-2">this week</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card rounded-1 border-0 shadow-sm p-3">
          <div class="info-box d-flex justify-content-between">
            <div class="d-flex">
              <div class="info-box_bar bg-info bg-opacity-50 me-3"></div>
              <div class="info-box_data d-flex flex-column align-items-start">
                <span class="fs-8 fw-bold text-secondary">Factor de Beneficio</span>
                <span class="fs-5 fw-bold text-primary-emphasis">0.00</span>
              </div>
            </div>
            <div>
              <div class="info-box_ico rounded-2 d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-arrow-up-right-dots fs-6 text-primary-emphasis"></i>
              </div>
            </div>
          </div>
          <div class="number-box d-flex mt-2">
            <div class="fs-8 fw-bold text-info text-opacity-50"><i class="fa-solid fa-up-long fs-9"></i> <span class="fs-8">+26%</span></div>
            <span class="fs-8 text-secondary text-opacity-50 ms-2">this week</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card rounded-1 border-0 shadow-sm p-3">
          <div class="info-box d-flex justify-content-between">
            <div class="d-flex">
              <div class="info-box_bar bg-success bg-opacity-50 me-3"></div>
              <div class="info-box_data d-flex flex-column align-items-start">
                <span class="fs-8 fw-bold text-secondary">Factor de Beneficio</span>
                <span class="fs-5 fw-bold text-primary-emphasis">0.00</span>
              </div>
            </div>
            <div>
              <div class="info-box_ico rounded-2 d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-arrow-up-right-dots fs-6 text-primary-emphasis"></i>
              </div>
            </div>
          </div>
          <div class="number-box d-flex mt-2">
            <div class="fs-8 fw-bold text-success text-opacity-50"><i class="fa-solid fa-up-long fs-9"></i> <span class="fs-8">+26%</span></div>
            <span class="fs-8 text-secondary text-opacity-50 ms-2">this week</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DashboardHome',
}
</script>

<style lang="scss">
  .info-box_bar{
    width: 3px;
    height: 100%;
    border-radius: 3px;

  }
  .info-box_ico{
    background-color: #efefef;
    height: 35px;
    width: 35px;
  }
</style>