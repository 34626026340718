<template>
  <div class="container">
    <div class="card rounded-1 border-0 shadow-sm py-5 px-4" :class="{'bg_dark': themeDark, 'bg-white': !themeDark}">
        <div class="line_box mb-5">
            <div class="title_box d-flex align-items-center mb-5">
              <div class="me-4">
                <i class="fa-solid fa-ellipsis-vertical me-2" :class="{'text-white': themeDark, 'text-primary-emphasis': !themeDark}"></i>
                <span class="fs-8 fw-bold" :class="{'text-white': themeDark, 'text-primary-emphasis': !themeDark}"
                  >Tu Lista de seguimiento de activos</span
                >
              </div>
              <div class="col border-bottom"></div>
            </div>
            <div class="list-item d-flex align-items-center">
              <button class="btn btn-primary rounded-1 px-3" data-bs-toggle="modal" data-bs-target="#ActivoModal">
                <span class="me-5 text-uppercase fs-8 fw-bold">agregar activo</span>
                <i class="fa-solid fa-plus fs-5"></i>
              </button>
              <div class="col list-item-boxs ps-4">
                <div class="row">
                  <div class="col-3" v-for="(activoItem, index) in listadeActivosPorUsuario" :key="index">
                    <div
                      class="card rounded-1 border-0 bg_dashboard p-0 shadow-sm overflow-hidden"
                    >
                      <div class="top_info d-flex justify-content-between align-items-center px-3">
                        <span class="fs-8 fw-bold text-white">{{ activoItem.activo.tipoActivo.nombre }}</span>
                        <div @click="eliminarActivo(activoItem.idSeguimientoPar)" class="p-0"><i class="cursor-pointer text-white fa-solid fa-circle-xmark"></i></div>
                      </div>
                      <div class="center_info text-start p-3">
                        <div>
                          <span class="fs-8 fw-bold text-primary-emphasis fs-4 lh-1">
                            {{ activoItem.activo.nombre }}
                          </span>
                        </div>
                        <div>
                          <span class="fs-8 text-secondary lh-1"> {{ activoItem.activo.descripcion }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
         <div class="line_box mt-5">
            <div class="title_box d-flex align-items-center mb-5">
              <div class="me-4">
                <i class="fa-solid fa-ellipsis-vertical me-2" :class="{'text-white': themeDark, 'text-primary-emphasis': !themeDark}"></i>
                <span class="fs-8 fw-bold" :class="{'text-white': themeDark, 'text-primary-emphasis': !themeDark}"
                  >Tu Lista de seguimiento de estrategias técnicas/fundamentales (por ejemplo, setups o patrones de entrada)</span
                >
              </div>
              <div class="col border-bottom"></div>
            </div>
            <div class="list-item d-flex align-items-center">
              <button class="btn btn-primary rounded-1 px-3" data-bs-toggle="modal" data-bs-target="#EstrategiaModal" @click="setIdEstrategia(null)">
                <span class="me-5 text-uppercase fs-8 fw-bold">agregar estrategia</span>
                <i class="fa-solid fa-plus fs-5"></i>
              </button>
              <div class="col list-item-boxs ps-4">
                <div class="row">
                  <div class="col-3 cursor-pointer" @click="setIdEstrategia(estrategia.idEstrategia)" v-for="(estrategia, index) in listadeEstrategiaPorUsuario" :key="index" data-bs-toggle="modal" data-bs-target="#EstrategiaModal">
                    <div
                      class="card rounded-1 border-0 bg_dashboard p-0 shadow-sm overflow-hidden"
                    >
                      <div class="top_info text-start px-3 d-flex justify-content-between align-items-center py-1">
                        <span class="fs-8 fw-bold text-white">{{  estrategia.nombre }}</span>
                        <!-- <div @click="eliminarEstrategia(estrategia.idEstrategia)" class="p-0"><i class="cursor-pointer text-white fa-solid fa-circle-xmark"></i></div> -->
                      </div>
                      <div class="center_info text-start p-3">
                        <div>
                          <span class="fs-8 fw-bold text-primary-emphasis fs-4 lh-1">
                            {{  estrategia.descripcion }}
                          </span>
                        </div>
                        <!-- <div>
                          <span class="fs-8 text-secondary lh-1"> SP500 </span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
  <modal-estrategia></modal-estrategia>
  <modal-activo></modal-activo>
</template>

<script>
import axios from "axios";
import ModalEstrategia from "@/components/modales/ModalEstrategia.vue";
import ModalActivo from "@/components/modales/ModalActivo.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "WatchList",
  computed: {
    ...mapGetters(['themeDark']),
    ...mapGetters("auth", ["getToken", "getUserId"]),
    ...mapGetters("watchlist", ["listadeActivosPorUsuario","listadeEstrategiaPorUsuario"]),
  },
  components:{ ModalEstrategia,ModalActivo},
  data(){
    return{
      estrategiaSeleccionada: null,
      shwConf : false
    }
  },
 async mounted(){
    this.getActivoByUser({userId: this.getUserId, token: this.getToken});
    this.getEstrategiaByUser({userId: this.getUserId, token: this.getToken});
    const myModalEl = document.getElementById('EstrategiaModal')
    myModalEl.addEventListener('show.bs.modal', event => {
      this.showAddConfirmaciopnModal(this.shwConf);
    });
    myModalEl.addEventListener('hidden.bs.modal', event => {
      this.estrategiaSeleccionada = null;
      this.shwConf = false;
    })
  },
  methods:{
    setIdEstrategia(id){
      this.shwConf = true;
      console.log('estrategia seleccionada', id);
      this.setIdModalEstrategia(id);
    },
    async eliminarActivo(id){
      try {
        await this.delActivoByIdActivo({activoId: id, token: this.getToken})
        this.getActivoByUser({userId: this.getUserId, token: this.getToken})
      } catch (error) {
        
      }
      // this.delActivoByIdActivo({activoId: id, token: this.getToken}).then(

      // )
      // this.getActivoByUser({userId: this.getUserId, token: this.getToken});
      setTimeout(() => {
        this.getActivoByUser({userId: this.getUserId, token: this.getToken})
      }, 500);
      
    },
    async eliminarEstrategia(id){
      await this.delEstrategiaByIdEstrategia({estrategiaId: parseInt(id), token: this.getToken})
      this.getEstrategiaByUser({userId: this.getUserId, token: this.getToken})
      // setTimeout(() => {
      // }, 500);
      
    },
    ...mapActions('watchlist', ['setIdModalEstrategia','getActivoByUser','delActivoByIdActivo' ,'delEstrategiaByIdEstrategia','getEstrategiaByUser','showAddConfirmaciopnModal']),
  }
};
</script>

<style lang="scss">
.list-item {
  button {
    display: block;
    height: 45px;
  }
  &-boxs {
    .card {
      min-height: 90px;
      .top_info {
        background-color: #A68DEC;
      }
      .center_info{
        div + div{
            position: relative;
            top: -5px;
        }
      }
    }
  }
}
</style>
