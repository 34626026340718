const mutations = {
    SET_LIST_ACTIVOS_BY_USER(state, value) {
        state.listadeActivosPorUsuario = value
    },
    SET_LIST_ESTRATEGIA_BY_USER(state, value) {
        state.listadeEstrategiaPorUsuario = value
    },
    SET_LIST_CONFIRMACION_BY_ESTRATEGIAID(state, value) {
        state.listadeConfimacionByEstrategiaID = value
    },
    SET_LIST_CONFIRMACION_BY_ESTRATEGIAID(state, value) {
        state.listadeConfimacionByEstrategiaID = value
    },
    SHOW_ADD_CONFIRMACON_MODAL(state, value) {
        state.addConfirmacion = value
    },
    SET_ID_ESTRATEGIA_MODAL(state, value) {
        state.idModalEstrategia = value
    },
};

export default mutations;