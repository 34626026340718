<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4616_355"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.0117188" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4616_355)">
      <path
        d="M2 19.0117V17.0117H12V19.0117H2ZM2 14.0117V12.0117H7V14.0117H2ZM2 9.01172V7.01172H7V9.01172H2ZM20.6 19.0117L16.75 15.1617C16.35 15.4451 15.9125 15.6576 15.4375 15.7992C14.9625 15.9409 14.4833 16.0117 14 16.0117C12.6167 16.0117 11.4375 15.5242 10.4625 14.5492C9.4875 13.5742 9 12.3951 9 11.0117C9 9.62839 9.4875 8.44922 10.4625 7.47422C11.4375 6.49922 12.6167 6.01172 14 6.01172C15.3833 6.01172 16.5625 6.49922 17.5375 7.47422C18.5125 8.44922 19 9.62839 19 11.0117C19 11.4951 18.9292 11.9742 18.7875 12.4492C18.6458 12.9242 18.4333 13.3617 18.15 13.7617L22 17.6117L20.6 19.0117ZM14 14.0117C14.8333 14.0117 15.5417 13.7201 16.125 13.1367C16.7083 12.5534 17 11.8451 17 11.0117C17 10.1784 16.7083 9.47005 16.125 8.88672C15.5417 8.30339 14.8333 8.01172 14 8.01172C13.1667 8.01172 12.4583 8.30339 11.875 8.88672C11.2917 9.47005 11 10.1784 11 11.0117C11 11.8451 11.2917 12.5534 11.875 13.1367C12.4583 13.7201 13.1667 14.0117 14 14.0117Z"
        
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTradeLog",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#4613E5" },
  },
};
</script>
