<template>
  <div class="container position-relative" :class="{'bg_dark': themeDark, 'p-4': themeDark, 'rounded-2': themeDark}">
    <div class="filter-box bg-white p-2 d-flex mb-4 justify-content-between" :class="{'bg_transparent': themeDark}">
      <div class="col-8 d-flex">
        <div class="col text-start me-3">
          <div class="dropdown">
            <a
              class="btn rounded-1 dropdown-toggle d-flex border border-primary-subtle align-items-center justify-content-between"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :class="{'bg_lightPink': themeDark, 'bg_transparent': !themeDark}"
            >
              <span class="fs-7 text-primary-emphasis fw-bold"> Mes </span>
            </a>
  
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </div>
        </div>
        <div class="col text-start">
          <div class="dropdown">
            <a
              class="btn rounded-1 dropdown-toggle d-flex border border-primary-subtle align-items-center justify-content-between"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :class="{'bg_lightPink': themeDark, 'bg_transparent': !themeDark}"
            >
              <span class="fs-7 text-primary-emphasis fw-bold"> Mes </span>
            </a>
  
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col text-end">
        <button class="btn border rounded-1 px-4" :class="{'btn-primary': themeDark}">
          <span class="fs-6 px-1" :class="{'text-white': themeDark, 'text-primary': !themeDark}">filter</span>
        </button>
      </div>
    </div>
    <div class="card rounded-1 border-0 shadow-0 bg-transparent py-3 px-0">      
      <div class="title_box d-flex align-items-center justify-content-between mb-4">
        <div class="me-4">
          <span class="fs-5 fw-bold"
          :class="{'text-white': themeDark, 'text-primary-emphasis': !themeDark}"
            >Información Destacada</span
          >
        </div>
        <div class="leyend_st d-flex justify-content-end align-items-center" :class="{'bg_lightPink': themeDark, 'px-2': themeDark, 'rounded-2': themeDark}">
          <div class="me-4 d-flex justify-content-end align-items-center">
            <i class="fa-solid fa-circle fs-8 me-1 text-success"></i>
            <span class="fs-8 text-success">Rentable</span>
          </div>
          <div class="me-4 d-flex justify-content-end align-items-center">
            <i class="fa-solid fa-circle fs-8 me-1 text-danger"></i>
            <span class="fs-8 text-danger">Pérdida</span>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <i class="fa-solid fa-circle fs-8 me-1 color-secondary-subtle"></i>
            <span class="fs-8 color-secondary-subtle">Neutral</span>
          </div>
        </div>
      </div>
      <div class="cards-section mb-5">
        <div class="row">
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-success bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-success bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-arrow-up fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-danger bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-danger bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-arrow-down fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-dark bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-dark bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-slash fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-danger bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-danger bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-arrow-down fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-success bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-success bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-arrow-up fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-danger bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-danger bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-arrow-down fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-dark bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-dark bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-slash fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 mb-3">
            <div class="card rounded-1 border-0 shadow-sm p-3">
              <div class="info-box d-flex justify-content-between">
                <div class="d-flex">
                  <div class="info-box_bar bg-danger bg-opacity-50 me-3"></div>
                  <div
                    class="info-box_data d-flex flex-column align-items-start"
                  >
                  <span class="fs-5 fw-semi-bold text-primary-emphasis">0.00</span>
                  <span class="fs-8 fw-semi-bold text-secondary"
                    >Factor de Beneficio</span
                  >
                  </div>
                </div>
                <div>
                  <div
                    class="info-box_ico bg-danger bg-opacity-50 rounded-2 d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="fa-solid fa-arrow-down fs-6 text-white"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="tablaTrades">
        <table class="table ">
          <thead class="">
            <tr>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">Estrategia</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">Activo</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">Operacion</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">F. Apertura</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">P. Apertura</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">F. Cierre</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">P. Cierre</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">P. Stop Loss</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">Beneficio B.</span></th>
              <th :class="{'bg_lightPink': themeDark, 'bg_thead': !themeDark}" scope="col"><span class="fs-7 fw-bold" :class="{'text-primary': themeDark, 'text-secondary': !themeDark}">Spread</span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(trade, index) in paginatedData" :key="index">
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.estrategia.nombre }}
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.seguimientoPar.activo.nombre }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.tipoOperacion.nombre }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ changeDate(trade.fechaApertura) }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.precioApertura }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ changeDate(trade.fechaCierre) }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.precioCierre }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.precioSl }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.beneficioBruto }}    
                </span>
              </td>
              <td>
                <span class="fs-7 text-secondary">
                  {{ trade.spread }}    
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex align-items-center justify-content-center" v-if="listaTrades.length > pageSize">
          <div class="pagination_ d-flex align-items-center justify-content-center">
            <div :class="{ 'opacity-25': currentPage === 1 }" class="pe-3">
              <span @click="prevPage()" aria-label="Anterior" class="d-flex align-items-center cursor-pointer">
                <i class="fa-solid fa-chevron-left text-secondary fs-5"></i>
              </span>
            </div>
            <div class="d-flex align-items-center col">
              <div v-for="page in totalPages" :key="page" :class="{ 'active': currentPage === page }" class="cursor-pointer d-flex align-items-center justify-content-center pages_item">
                <span @click="changePage(page)" :class="{ 'rounded-circle bg-primary text-white': currentPage === page, 'text-primary-emphasis': currentPage != page }" class="d-flex align-items-center justify-content-center w-100 h-100 fs-7 fw-bold">{{ page }}</span>
              </div>
            </div>
            <div :class="{ 'opacity-25': currentPage === totalPages }" class="ps-3">
              <span @click="nextPage()" aria-label="Siguiente" class="d-flex align-items-center cursor-pointer">
                <i class="fa-solid fa-chevron-right text-secondary fs-5"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn_float rounded-circle pa-4 bg-primary border-0 shadow-sm" data-bs-toggle="modal" data-bs-target="#tradingModal">
      <span class="text-white">
        <i class="fa-solid fa-plus fs-4"></i>
      </span>
    </button>
  </div>
  <modal-trading/>
</template>

<script>
import ModalTrading from "@/components/modales/ModalTrading.vue";
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
export default {
  name: "Bitacora",
  data:()=>{
    return{
      archivoSeleccionado: null,
      currentPage: 1,
      pageSize: 5
    }
  },
  components:{ ModalTrading },
  computed: {
    ...mapGetters(['themeDark']),
    ...mapGetters("auth", ["getToken", "getUserId"]),
    ...mapGetters("bitacora", ["listaTrades"]),
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.listaTrades.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.listaTrades.length / this.pageSize);
    }
  },
  async mounted(){
    await this.getTradePorUsuario({userId: this.getUserId, token: this.getToken});
  },
  methods:{
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    getFileUpload(event){
      this.archivoSeleccionado = event.target.files[0];
    },
    postImage(){
      const formData = new FormData();
      formData.append('image', this.archivoSeleccionado);
      console.log('subida de imagen', this.archivoSeleccionado);
    },
    ...mapActions('bitacora', ['getTradePorUsuario']),
    changeDate(date){
      return moment(date).format('DD/MM/YYYY HH:mm');
    }
  }
};
</script>

<style lang="scss">
.pagination_{
  .pages_item{
    width: 30px;
    height: 30px;
  }
}
</style>
