<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4614_5029"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.0117188" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4614_5029)">
      <path
        d="M3 11.0117V3.01172H11V11.0117H3ZM3 21.0117V13.0117H11V21.0117H3ZM13 11.0117V3.01172H21V11.0117H13ZM13 21.0117V13.0117H21V21.0117H13Z"
        
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconDashboard",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#4613E5" },
  },
};
</script>
