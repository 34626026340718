<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4614_5117)">
      <path
        d="M10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11Z"
        :stroke="fill"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 21V19C18 17.9391 17.5786 16.9217 16.8284 16.1716C16.0783 15.4214 15.0609 15 14 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21"
        :stroke="fill"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4614_5117">
        <rect width="20" height="20" :fill="fill" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconBitacora",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "20" },
    height: { type: String, default: "20" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#5F617A" },
  },
};
</script>
