import { apiGet, apiPost } from "@/store/watchlist/api";
import axios from "axios";
const actions = {
  getTipoOperacion({ commit }, token) {
    
    axios
    .get(
      `https://api.es2trading.com/api/tipoOperacion/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      commit("SET_LIST_TIPO_OPERACION", response.data);
    })
    .catch((error) => {
      console.error("Error al hacer la solicitud:", error);
    });
  },
  getTradePorUsuario({ commit }, payload) {
    const {userId, token} = payload;
    axios
    .get(
      `https://api.es2trading.com/api/trade/trade/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      // console.log('listado de trades correcto', response.data);
      commit("SET_LIST_TRADES", response.data);
    })
    .catch((error) => {
      console.error("Error al hacer la solicitud:", error);
    });
  },
  async getTradesPorIdTrade({ commit }, payload) {
    const {tradeId, token} = payload;
    try {
      return await axios
        .get(
          `https://api.es2trading.com/api/trade/${tradeId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    } catch (error) {
      console.error("Error al hacer la solicitud:", error);
    }
  },
  async getConfirmacionTradesPorIdTrade({ commit }, payload) {
    const {tradeId, token} = payload;
    try {
      return await axios
        .get(
          `https://api.es2trading.com/api/confirmacionestrade/trade/${tradeId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    } catch (error) {
      console.error("Error al hacer la solicitud:", error);
    }
  }
};

export default actions;
