<template>
  <!--Logo start-->
  <div class="logo-main" :style="{ opacity: opacity }">
    <svg
      class="text-white"
      :width="width"
      :height="height"
      viewBox="0 0 42 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0035 5.30186C32.0035 8.14234 31.2059 10.9275 29.7022 13.3457L18.2875 31.7032H12.1839C10.505 31.7032 9.14355 30.3559 9.14355 28.6943C9.14355 25.8539 9.94111 23.0687 11.4448 20.6505L22.8595 2.29297H28.9631C30.642 2.29297 32.0035 3.64031 32.0035 5.30186Z"
        :fill="fill"
      />
      <path
        d="M11.2039 11.3429H0L5.97151 1.49171C6.52015 0.586777 7.51074 0.03125 8.57754 0.03125H20.5714L15.0062 9.21128C14.2036 10.5335 12.7609 11.3429 11.2013 11.3429H11.2039Z"
        :fill="fill"
      />
      <path
        d="M32.568 33.9679H20.5742L26.1393 24.7879C26.942 23.4657 28.3847 22.6562 29.9442 22.6562H41.1481L35.1766 32.5074C34.628 33.4124 33.6374 33.9679 32.5706 33.9679H32.568Z"
        :fill="fill"
      />
    </svg>
  </div>
  <!--Logo End-->
</template>

<script>
export default {
  name: "Logo",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#4613E5" },
  },
};
</script>
