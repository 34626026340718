const actions = {
  setUserId({ commit }, value) {
    commit("SET_USERID", value);
  },
  setUserName({ commit }, value) {
    commit("SET_USERNAME", value);
  },
  setUserLastName({ commit }, value) {
    commit("SET_USERLASTNAME", value);
  },
  setUserEmail({ commit }, value) {
    commit("SET_USEREMAIL", value);
  },
  setToken({ commit }, value) {
    commit("SET_TOKEN", value);
  },
};

export default actions;
