<template>
  <div class="d-flex flex-column justify-content-between h-100">
    <div>
      <div class="logo-st ps-3 d-flex align-items-center justify-content-center">
        <div class="me-3">
          <logo :width="35" :height="35"></logo>
        </div>
        <div>
          <logo-name :width="115" :fill="(themeDark) ? '#ffffff' : '#0A031C'" class="pt-1"></logo-name>
        </div>
      </div>
      <div class="separator w-100 mb-2 opacity-75"></div>
      <div class="nav_menu text-start px-2">
        <div class="pt-1">
          <button class="btn btn-primary rounded-1 px-4 w-100 text-start">
            <i class="fa-solid fa-plus me-3"></i>
            <span class="fs-7">Añadir Trade</span>
          </button>
        </div>
        <ul class="p-0 m-0">
          <li class="d-block align-items-center">
            <router-link
              to="/dashboard"
              class="ps-4 d-flex align-items-center py-2 rounded-1"
            >
              <span class="me-3">
                <icon-dashboard></icon-dashboard>
              </span>
              <span class="fs-6">Dashboard</span>
            </router-link>
          </li>
          <li class="d-block align-items-center">
            <router-link
            to="/dashboard/roles-permisos"
            class="ps-4 d-flex align-items-center py-2 rounded-1"
            >
            <span class="me-3">
                <icon-roles></icon-roles>
            </span>
              <span class="fs-6">Roles & Profiles</span>
            </router-link>
          </li>
          <li class="d-block align-items-center">
            <router-link
              to="/dashboard/watch-list"
              class="ps-4 d-flex align-items-center py-2 rounded-1"
            >
            <span class="me-3">
                <icon-watch-list></icon-watch-list>
            </span>
              <span class="fs-6">Watch List</span>
            </router-link>
          </li>
          <li class="d-block align-items-center">
            <router-link
              to="/dashboard/bitacora"
              class="ps-4 d-flex align-items-center py-2 rounded-1"
            >
            <span class="me-3">
                <icon-bitacora></icon-bitacora>
            </span>
              <span class="fs-6">Bitacora</span>
            </router-link>
          </li>
          <li class="d-block align-items-center">
            <router-link
              to="/dashboard/daily-journal"
              class="ps-4 d-flex align-items-center py-2 rounded-1"
            >
            <span class="me-3">
                <icon-daily-journal></icon-daily-journal>
            </span>
              <span class="fs-6">Daily Journal</span>
            </router-link>
          </li>
          <li class="d-block align-items-center">
            <router-link
              to="/dashboard/trade-log"
              class="ps-4 d-flex align-items-center py-2 rounded-1"
            >
            <span class="me-3">
                <icon-trade-log></icon-trade-log>
            </span>
              <span class="fs-6">Trade Log</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    
    <div class="ps-4 d-flex pb-4">
      <div class="form-check form-switch">
          <input
            class="form-check-input cursor-pointer"
            type="checkbox"
            id="flexSwitchCheckDefault"
            :checked="themeDark"
            @change="handleSwitchChange"
            :style="{ backgroundColor:(!themeDark) ? '#ffffff' : '#512ACD' }"
          />
          <label class="form-check-label ps-2 cursor-pointer" for="flexSwitchCheckDefault">
            <span :style="{ color:(themeDark) ? '#ffffff' : '#5F617A' }">
              Cambiar de Tema
            </span>
            <span class="ms-2">
              <i class="fa-solid fa-moon" v-if="themeDark" :style="{ color:(themeDark) ? '#ffffff' : '#5F617A' }"></i>
              <i class="fa-solid fa-circle fs-8" v-if="!themeDark" :style="{ color:(themeDark) ? '#ffffff' : '#512ACD' }"></i>
            </span>
          </label>
        </div>
    </div>
    <!-- <div class="border-bottom"></div> -->
  </div>
</template>

<script>
import Logo from "@/components/global/Logo.vue";
import LogoName from "@/components/global/LogoName.vue";
import IconDashboard from "@/components/icons/IconDashboard.vue";
import IconRoles from "@/components/icons/IconRoles.vue";
import IconWatchList from "@/components/icons/IconWatchList.vue";
import IconBitacora from "@/components/icons/IconBitacora.vue";
import IconDailyJournal from "@/components/icons/IconDailyJournal.vue";
import IconTradeLog from "@/components/icons/IconTradeLog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Sidebar",
  components: { Logo, LogoName, IconDashboard, IconRoles, IconWatchList, IconBitacora, IconDailyJournal, IconTradeLog },
  computed: {
    ...mapGetters(['themeDark']),
  },
  mounted(){
    // console.log('carga sidebar ', this.themeDark);
  },
  methods: {
    ...mapActions(['setDarkTheme']),
    handleSwitchChange(event) {
      let idApp = document.getElementById('app');
      console.log('ssss', idApp);
      this.setDarkTheme(event.target.checked);
      (this.themeDark) ? idApp.classList.add('dark_theme') : idApp.classList.remove('dark_theme');
    }
  },
  watch:{
    themeDark(new_, old){
      console.log('dark', new_);
    }
  }
};
</script>

<style lang="scss">
.logo-st {
  height: 60px;
}
.nav_menu {
  ul {
    li {
      list-style: none;
      margin-bottom: 1px;
      a {
        transition: all ease-in 0.2s;
        text-decoration: none;
        span{
          color: #5F617A;
          svg {
            fill:#5F617A
          }
        }
        &.router-link-exact-active,
        &.active,
        &:hover {
          // color: #fff !important;
          background-color: #ECE4FA;
          span{
            color: #512ACD;
            svg {
              fill:#512ACD
            }
          }
        }
      }
    }
  }
}
</style>
