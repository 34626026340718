import { createStore } from 'vuex'
import auth from './auth'
import watchlist from './watchlist'
import bitacora from './bitacora'

export default createStore({
  state: {
    Dark_theme: false,
  },
  getters: {
    themeDark: state => state.Dark_theme,
  },
  mutations: {
    SET_DARK_THEME(state, value) {
      state.Dark_theme = value
    },
  },
  actions: {
    setDarkTheme({ commit }, value) {
      commit("SET_DARK_THEME", value);
    },
  },
  modules: {
    auth,
    watchlist,
    bitacora
  }
})
