<template>
  <div
    class="modal fade"
    id="profileModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropPermissionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body p-0">
          <!-- <button
            type="button"
            class="border-0 bg_transparent p-0 position-absolute close_modal z-3"
            data-bs-dismiss="modal"
          >
            <i class="fa-solid fa-circle-xmark text-primary fs-2"></i>
          </button> -->
          <div class="text-start mb-3 pt-3 px-5">
            <span class="text-dark fs-4 fw-bold">Profile</span>
          </div>
          <div class="ps-2 pe-5 pb-3 d-flex align-items-center bg-white col-12">
            <div class="col-4 d-flex justify-content-center align-items-center flex-column">
              <div
                class="picture_profile d-flex justify-content-center align-items-center rounded-circle border border-color-secondary"
              >
                <span>
                  <icon-user :fill="'#5F617A'" :width="50" :height="50"></icon-user>
                </span>
              </div>
              <div>
                <button
                    class="btn btn-primary bg_dashboard rounded-1 px-3 border mt-3"
                    style="background-color: #ECE4FA !important;"
                  >
                    <span class="fs-7 border-color-secondary text-primary"
                      >Cambiar foto de usuario</span
                    >
                  </button>
              </div>
            </div>
            <div class="col">
              <div class="form_body w-100 ps-3">
                <div class="d-flex mb-4">
                  <div class="col-12 px-1 d-flex flex-column align-items-start">
                    <span class="fs-6 text-dark mb-1 fs-semi-bold"
                      >First Name</span
                    >
                    <div
                    class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                    :class="{ 'opacity-25': disable_inputs }"
                    > <div
                        class="input_st w-100 d-flex align-items-center"
                      >
                        <input
                          type="text"
                          class="form-control border-0 fs-7 d-block"
                          v-model="userName"
                          :disabled="disable_inputs"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="col-12 px-1 d-flex flex-column align-items-start">
                    <span class="fs-6 text-dark mb-1 fs-semi-bold"
                      >Last Name</span
                    >
                    <div
                      class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                      :class="{ 'opacity-25': disable_inputs }"
                    ><div
                        class="input_st w-100 d-flex align-items-center"
                      >
                        <input
                          type="text"
                          class="form-control border-0 fs-7 d-block"
                          v-model="userLastName"
                          :disabled="disable_inputs"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <div class="col-12 px-1 d-flex flex-column align-items-start">
                    <span class="fs-6 text-dark mb-1 fs-semi-bold">Email</span>
                    <div
                      class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                      :class="{ 'opacity-25': disable_inputs }"
                    >
                    <div
                        class="input_st w-100 d-flex align-items-center"
                      >
                        <input
                          type="text"
                          class="form-control border-0 fs-7 d-block"
                          v-model="userEmail"
                          :disabled="disable_inputs"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-start mb-2">
                    <div v-if="loading">
                      <div
                        class="spinner-grow"
                        style="width: 13px; height: 13px"
                        role="status"
                      ></div>
                      <span class="ms-2 fs-9 text-secondary opacity-50"
                        >Actualizando datos del usario</span
                      >
                    </div>
                    <div v-if="success_" class="d-flex align-items-center">
                      <span
                        ><i class="fa-regular fa-circle-check text-success"></i
                      ></span>
                      <span class="ms-2 fs-9 text-success"
                        >Usuario actualizado Correctamente</span
                      >
                    </div>
                    <div v-if="error_" class="d-flex align-items-center">
                      <span
                        ><i
                          class="fa-solid fa-circle-exclamation opacity-75 text-danger"
                        ></i
                      ></span>
                      <span class="ms-2 fs-9 text-danger opacity-75 text-start">
                        {{ errorMessage }}</span
                      >
                    </div>
                  </div>
                  <div class="text-end">
                    <div
                      class="btn btn-primary bg_dashboard rounded-5 px-4 border-0 me-3"
                      data-bs-dismiss="modal"
                    >
                      <span class="fs-7 fw-bold text-primary-emphasis"
                        >Cerrar</span
                      >
                    </div>
                    <div
                      v-if="disable_inputs"
                      class="btn btn-primary rounded-5 px-4"
                      @click="disable_inputs = false"
                    >
                      <span class="fs-7 fw-bold">Editar</span>
                    </div>
                    <div
                      v-if="!disable_inputs"
                      class="btn btn-primary rounded-5 px-4"
                      @click="updateUserData()"
                    >
                      <span class="fs-7 fw-bold">Guardar Cambios</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth, Amplify } from "aws-amplify";
import IconUser from "@/components/icons/IconUser.vue";
import { mapGetters } from 'vuex';
export default {
  name: "ModalProfile",
  data() {
    return {
      userName: "",
      userLastName: "",
      userEmail: "",
      disable_inputs: true,
      errorMessage: "Las contraseñas deben ser iguales",
      loading: false,
      success_: false,
      error_: false,
    };
  },
  components: { IconUser },
  computed: {
    ...mapGetters('auth', ['getUserName', 'getUserLastName', 'getUserEmail']),
  },
  mounted() {
    const myModalEl = document.getElementById("profileModal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      this.disable_inputs = true;
      // this.userName = "";
      // this.userLastName = "";
      // this.userEmail = "";
    });
  },
  async created() {
    const awsConfig = {
      Auth: {
        region: "us-east-2",
        userPoolId: "us-east-2_dIvLCE7eX",
        userPoolWebClientId: "19musn4gq65rjutoo2qtb0umb6",
      },
    };
    Amplify.configure(awsConfig);
    const user = await Auth.currentAuthenticatedUser();
    this.userName = user.attributes['custom:firstname'];
    this.userLastName = user.attributes['custom:lastname'];;
    this.userEmail = user.attributes.email;
  },
  methods: {
    async updateUserData(){
      this.loading = true;
      const user = await Auth.currentAuthenticatedUser();
      const updatedAttributes = {
        email: this.userEmail,
        'custom:firstname': this.userName,
        'custom:lastname': this.userLastName
      }
      await Auth.updateUserAttributes(user, updatedAttributes).then(()=>{
        this.success_ = true;
        this.loading = false;          
        setTimeout(() => {
          this.disable_inputs = true;
          this.success_ = false;
        }, 3000);

      }).catch((error) => {
        this.loading = false;
        this.error_ = true;
        this.errorMessage = error.message;
        setTimeout(() => {
          this.error_ = false;
        }, 3000);
      });
    }
  },
  watch: {},
};
</script>

<style lang="scss">
.picture_profile {
  height: 180px;
  width: 180px;
}
</style>
