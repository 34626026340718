import { apiGet, apiPost } from "@/store/watchlist/api";
import axios from "axios";
const actions = {
  async getActivoByUser({ commit }, payload) {
    try {
      const activoData = await apiGet.getActivoByUser(payload)
      commit("SET_LIST_ACTIVOS_BY_USER", activoData.data);
    } catch (error) {
      console.error("Error al obtener activos por usuario:", error);
    }
  },
  delActivoByIdActivo({ commit }, payload) {
    const { activoId, token } = payload;
    axios
      .delete(
        `https://api.es2trading.com/api/seguimientoPar/${activoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log('activo eliminado', response);
      })
      .catch((error) => {
        console.error("Error al hacer la solicitud:", error);
      });
  },
  async getEstrategiaByUser({ commit }, payload) {
    try {
      let estrategiaData = await apiGet.getEstrategiaByUser(payload);
      commit("SET_LIST_ESTRATEGIA_BY_USER", estrategiaData.data);
    } catch (error) {
      console.error("Error al hacer la solicitud:", error);
    }
  },
  delEstrategiaByIdEstrategia({ commit }, payload) {
    const { estrategiaId, token } = payload;
    axios
      .delete(
        `https://api.es2trading.com/api/estrategia/${estrategiaId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(" eliminado", response);
      })
      .catch((error) => {
        console.error("Error al hacer la solicitud:", error);
      });
  },
  resetConfimacionesList({ commit }) {
    commit("SET_LIST_CONFIRMACION_BY_ESTRATEGIAID", []);
  },
  async getConfirmacionByIdEstrategia({ commit }, payload) {
    try {
      const confirmacionData = await apiGet.getConfirmacionByIdEstrategia(payload);
      // console.log('agrega confirmacion estrategia', confirmacionData);
      commit("SET_LIST_CONFIRMACION_BY_ESTRATEGIAID", confirmacionData.data);
      return confirmacionData;
    } catch (error) {
      console.error("Error al obtener activos por usuario:", error);
    }
  },
  async getResultadosByConfirmacionID({ commit }, payload) {
    try {
      return  await apiGet.getResultadosByConfirmacionID(payload);
      console.log('resultado con', resultadoData);
      // commit("SET_LIST_CONFIRMACION_BY_ESTRATEGIAID", confirmacionData.data);
    } catch (error) {
      console.error("Error al obtener activos por usuario:", error);
    }
  },
  async crearEstrategia({ commit }, payload) {
    
    try {
      const confirmacionData = await apiPost.addEstrategia(payload);
      // console.log('payload',confirmacionData);
      // commit("SET_LIST_CONFIRMACION_BY_ESTRATEGIAID", confirmacionData.data);
    } catch (error) {
      console.error("Error al obtener activos por usuario:", error);
    }
  },
  showAddConfirmaciopnModal({ commit }, value) {
    commit("SHOW_ADD_CONFIRMACON_MODAL", value);
  },
  setIdModalEstrategia({ commit }, value) {
    commit("SET_ID_ESTRATEGIA_MODAL", value);
  },
  delConfirmacionByIdEstrategia({ dispatch, state }, payload) {
    const { estrategiaId,confirmacionId, token } = payload;
    axios
      .delete(
        `https://api.es2trading.com/api/confirmacionesestrategia/${confirmacionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(" Confirmacion Eliminada", state.idModalEstrategia);
        dispatch('getConfirmacionByIdEstrategia', {estrategiaId:state.idModalEstrategia, token});
        
        return response
      })
      .catch((error) => {
        console.error("Error al hacer la solicitud ---- confirmacion:", error);
      });
  },
  delResultadoByConfirmacionId({ commit }, payload) {
    const { resultadoId, token } = payload;
    axios
      .delete(
        `https://api.es2trading.com/api/resultadoConfirmacion/${resultadoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(" resultado Eliminada", response);
      })
      .catch((error) => {
        console.error("Error al hacer la solicitud --- Resultado:", error);
      });
  },
};

export default actions;
