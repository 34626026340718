<template>
  <div
    class="modal fade"
    id="EstrategiaModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropPermissionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-0">
          <button
            type="button"
            class="border-0 bg_transparent p-0 position-absolute close_modal z-3"
            data-bs-dismiss="modal"
          >
            <i class="fa-solid fa-circle-xmark text-primary fs-2"></i>
          </button>
          <div class="py-4">
            <h1 class="fs-4 fw-bold text-primary-emphasis m-0">
              {{
                !addConfirmacion ? "Agregar Estrategia" : "Agregar Confirmación"
              }}
            </h1>
          </div>
          <div class="px-5 estrategia_tabs" v-if="!addConfirmacion">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="add-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#add"
                  type="button"
                  role="tab"
                  aria-controls="add"
                  aria-selected="true"
                >
                  <span class="fs-7 text-primary-emphasis">
                    Crear Estrategia
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link disabled"
                  id="search-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#search"
                  type="button"
                  role="tab"
                  aria-controls="search"
                  aria-selected="false"
                >
                  <span class="fs-7 text-primary-emphasis">
                    Buscar Estrategia
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="add"
                role="tabpanel"
                aria-labelledby="add-tab"
              >
                <div class="content_base">
                  <form action="" class="p-4">
                    <div class="mb-3 text-start">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label fs-8 text-secondary"
                        >Nombre de la Estrategia</label
                      >
                      <input
                        type="text"
                        class="form-control rounded-1"
                        id="exampleFormControlInput1"
                        v-model="nombreEstrategia"
                        placeholder="Nombre"
                      />
                    </div>
                    <div class="text-start mb-1">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label fs-8 text-secondary"
                        >Descripción de la Estrategia</label
                      >
                      <input
                        type="text"
                        class="form-control rounded-1"
                        id="exampleFormControlInput1"
                        v-model="descripcionEstrategia"
                        placeholder="Descripción"
                      />
                    </div>
                    <div class="mt-4 text-end">
                      <div
                        class="btn btn-primary bg_dashboard rounded-5 px-4 border-0 me-3"
                        data-bs-dismiss="modal"
                      >
                        <span class="fs-7 fw-bold text-primary-emphasis"
                          >cancelar</span
                        >
                      </div>
                      <div
                        class="btn btn-primary rounded-5 px-4"
                        @click="addEstrategia()"
                      >
                        <span class="fs-7 fw-bold">Agregar Estrategia</span>
                    </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="search"
                role="tabpanel"
                aria-labelledby="search-tab"
              >
                <div class="content_base">b</div>
              </div>
            </div>
          </div>
          <div class="confirmacion_st pb-4" v-if="addConfirmacion">
            <div
              class="confirmacion_st-container d-flex justify-content-center"
            >
              <div class="w-75">
                <button
                  class="btn border-primary bg-primary-subtle w-100 rounded-1 px-4 d-flex justify-content-between align-items-center"
                  @click="addConfirmacionFn"
                >
                  <span class="fs-7 fw-bold text-primary-emphasis">
                    Agrega una Confirmación
                  </span>
                  <i
                    class="fa-solid fa-plus fs-6 text-primary-emphasis text pt-1"
                  ></i>
                </button>
                <div class="d-flex align-items-center mb-3 mt-4">
                  <div class="me-4">
                    <i class="fa-solid fa-ellipsis-vertical me-2"></i
                    ><span class="fs-8 text-primary-emphasis fw-bold"
                      >Lista de Confimaciones</span
                    >
                  </div>
                  <div class="col border-bottom pt-1"></div>
                </div>
                <div class="st-lista-confimacion">
                  <div v-if="listadeConfimacionByEstrategiaID.length == 0" class="opacity-5">
                    <div class="pt-4 opacity-50">
                      <span class="color-secondary-subtle fs-7 fw-bold me-2">NO TIENE CONFIRMACIONES</span>
                    </div>
                  </div>
                  <div v-else class="position-relative st-lista-confimacion-item mb-2 border border-primary-subtle p-1 rounded-1 d-flex justify-content-between" v-for="(confirmaciones, index) in confirmacionesLoad" :key="index">
                    <div class="col-7 d-flex justify-content-start align-items-center">
                      <span class="ps-1 fs-8 fw-bold text-secondary">{{  confirmaciones.nombre }}</span>
                    </div>
                    <div class="col">
                      <div class="input_section">
                        <select class="form-select border-0 rounded-5 fs-8 text-primary-emphasis bg-primary-subtle" aria-label="Default select example">
                          <option value="0" selected>-- Resultado --</option>
                          <option :value="index" v-for="(resultadoItem, index) in confirmaciones.resultados" :key="index">{{ resultadoItem.valor }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="delConfirmacion position-absolute cursor-pointer" @click="eliminarConfirmacion(confirmaciones.idConfirmacionEstrategia)">
                      <i class="fa-solid fa-trash text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="confirmacion_add_modal position-absolute bg-white h-100 top-0 rounded-3 d-flex justify-content-center align-items-center" :class="{'active-modal': showAddConfirmacion}">
            <div class="w-100 ">
              <form action="" class="px-4 py-5">
                <h1 class="fs-6 text-start text-primary-emphasis fw-bold mb-3">Crear Confirmación</h1>
                <div>
                  <div class="line_ d-flex align-items-center mb-2">
                    <div class="col-4 text-start">
                      <span class="fs-8 text-secondary">Nombre Confirmación:</span>
                    </div>
                    <div class="col ps-2">
                      <input type="text" class="w-100 form-control pt-1 pb-1 rounded-1 fs-8 text-primary-emphasis" v-model="confirmacionNombre">
                    </div>
                  </div>
                  <div class="line_ d-flex align-items-center mb-2">
                    <div class="col-4 text-start">
                      <span class="fs-8 text-secondary">Descripción Confirmación:</span>
                    </div>
                    <div class="col ps-2">
                      <input type="text" class="w-100 form-control pt-1 pb-1 rounded-1 fs-8 text-primary-emphasis" v-model="confirmacionDesc">
                    </div>
                  </div>
                  <div class="line_ d-flex align-items-center mb-2">
                    <div class="col-4 text-start">
                      <span class="fs-8 text-secondary">Resultado:</span>
                    </div>
                    <div class="col ps-2">
                      <input type="text" class="w-100 form-control pt-1 pb-1 rounded-1 resultado_input fs-8 text-primary-emphasis" placeholder="Enter(↵) para agregar resultado" v-model="badge" @keypress.enter="addItem(badge.trim())">
                    </div>
                  </div>
                  <div class="line_ d-flex align-items-center mt-2">
                    <div class="col-4 text-start"></div>
                    <div class="col d-flex">
                      <div class="badge bg-primary-subtle ms-2 fs-8 text-primary-emphasis" v-for="(resultado, index) in resultados" :key="index">{{ resultado.valor }} <i class="fa-solid fa-xmark ms-2 cursor-pointer" @click="deleteBadge(index)"></i></div>
                      <!-- <div class="badge bg-primary-subtle ms-2 fs-8 text-primary-emphasis">Bajista <i class="fa-solid fa-xmark ms-2"></i></div> -->
                    </div>
                  </div>
                </div>
                <div class="mt-4 text-end">
                      <div
                        class="btn btn-primary bg_dashboard rounded-5 px-4 border-0 me-3"
                        @click="backConfirmacion"
                      >
                        <span class="fs-7 fw-bold text-primary-emphasis"
                          >Regresar</span
                        >
                      </div>
                      <div
                        class="btn btn-primary rounded-5 px-4"
                        @click="crearConfirmacion"
                      >
                        <span class="fs-7 fw-bold">Crear</span>
                      </div>
                    </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ModalEstrategia",
  data() {
    return {
      // addConfirmacion: false,
      showAddConfirmacion: false,
      nombreEstrategia: '',
      descripcionEstrategia: '',
      confirmacionNombre: '',
      confirmacionDesc: '',
      idEstrategiaCreated: null,
      badge: '',
      resultados: [],
      confirmacionesLoad: [],
      confirmacion_list: [
        {
          name:'Confirmacion 1'
        },
        {
          name:'Confirmacion 2'
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getToken", "getUserId"]),
    ...mapGetters("watchlist", ["listadeEstrategiaPorUsuario","listadeConfimacionByEstrategiaID","addConfirmacion","idModalEstrategia"]),
  },
  mounted() {
    const myModalEl = document.getElementById('EstrategiaModal')
    myModalEl.addEventListener('hidden.bs.modal', event => {
      // this.addConfirmacion = false
      this.showAddConfirmaciopnModal(false);
      this.showAddConfirmacion = false
      this.idEstrategiaCreated = null,
      this.badge = '';
      this.resultados = [],
      this.confirmacionesLoad = [],
      this.resetConfimacionesList();
      this.setIdModalEstrategia(null);
    })
  },
  methods: {
    deleteBadge(index){
      this.resultados = this.resultados.filter((item, i) => i !== index);
    },
    ...mapActions('watchlist', ['delResultadoByConfirmacionId','delConfirmacionByIdEstrategia','setIdModalEstrategia','getEstrategiaByUser','getConfirmacionByIdEstrategia','resetConfimacionesList','crearEstrategia','getResultadosByConfirmacionID','showAddConfirmaciopnModal']),
    addItem(e) {
      
      if (e !== '') {
        let itemResultado = {
          valor: e,
          estado: "1"
        }
        this.resultados.push(itemResultado);
        this.badge = ''; // Clear the input field
      }
    },
    async eliminarConfirmacion(id){
      // try {
        let confirmacionToDelete = this.confirmacionesLoad.find((c)=> c.idConfirmacionEstrategia == id)
        if(confirmacionToDelete.resultados.length !== 0){
          confirmacionToDelete.resultados.map((r, index)=>{
            this.delResultadoByConfirmacionId({resultadoId: r.idResultado, token: this.getToken}).finally(()=>{
              confirmacionToDelete.resultados = confirmacionToDelete.resultados.filter((f)=>{f.idResultado !== r.idResultado})
              console.log('Resultado eliminado', r, confirmacionToDelete.resultados);
            });
          })
        }
        console.log('-----');
        let responseResult = await this.delConfirmacionByIdEstrategia({estrategiaId: this.idEstrategiaCreated, confirmacionId:id, token: this.getToken}).finally(()=>{
          this.confirmacionesLoad = this.confirmacionesLoad.filter((c)=>c.idConfirmacionEstrategia !== id)
          console.log('despues');
        })
    },
    async backConfirmacion(id){
      try {
        this.confirmacionesLoad = []        
        this.getConfirmacionByIdEstrategia({estrategiaId: this.idEstrategiaCreated, token: this.getToken}).then((response)=>{
          this.confirmacionesLoad = this.listadeConfimacionByEstrategiaID;
          this.showAddConfirmacion = false;
          if(!id) this.showAddConfirmaciopnModal(false);
          this.confirmacionNombre = '';
          this.confirmacionDesc = '';
          this.resultados = [],
          this.badge = '';
          this.confirmacionesLoad.map((c)=>{
            c.resultados = [];
            let res_ = this.getResultadosByConfirmacionID({token: this.getToken, confirmacionId: c.idConfirmacionEstrategia})
            let resp_ = []
            res_.then((response)=>{
              response.data.map((r)=>{
                c.resultados.push({valor:r.valor,idResultado: r.idResultadoConfirmacion, idConfirmacion: r.confirmacionesEstrategia.idConfirmacionEstrategia})
                
              })
            }
          )
          
        });
      })
      } catch (error) {
        
      }
    },
    crearConfirmacion(){
      
      let data = {
        idEstrategia: this.idEstrategiaCreated,
        usuario: this.getUserId,
        nombre: this.confirmacionNombre,
        descripcion: this.confirmacionDesc,
        estado: "1",
        resultadosConfirmacion: this.resultados
      };
      console.log('crea0 confirmacion', data);
      const headers = {
        'Authorization': `Bearer ${this.getToken}`,
        'Content-Type': 'application/json'
      };
      
      axios.post('https://api.es2trading.com/api/confirmacionesestrategia/', data, { headers })
      .then(response => {
        console.log('creo confirmacion', response.data);
        this.confirmacionNombre = '';
        this.confirmacionDesc = '';
        this.resultados = [];
        this.badge = '';
      })
      .catch(error => {
      // Manejar el error aquí
        console.error(error);
      });
    },
    addEstrategia() {
      
      let body = {
        usuario: this.getUserId,
        nombre: this.nombreEstrategia,
        descripcion: this.descripcionEstrategia,
        estado: "1"
      };
      const headers = {
        'Authorization': `Bearer ${this.getToken}`,
        'Content-Type': 'application/json'
      };
      // console.log('estarte', data);
      axios.post('https://api.es2trading.com/api/estrategia/', body, { headers })
      .then(response => {
        this.nombreEstrategia = '';
        this.descripcionEstrategia = '';
        // this.addConfirmacion = true;
        this.showAddConfirmaciopnModal(true);
        this.idEstrategiaCreated = response.data.idEstrategia
        console.log('crea categoria', response.data.idEstrategia);
      })
      .catch(error => {
        // Manejar el error aquí
          console.error(error);
        });
    },
    addConfirmacionFn(){
      console.log('iddd', this.idEstrategiaCreated);
      // this.idModalEstrategia
      // this.showAddConfirmaciopnModal(true);
      this.showAddConfirmacion =  true;
      
    }
  },
  watch:{
    async addConfirmacion(n){
      if(n){
        this.getEstrategiaByUser({userId: this.getUserId, token: this.getToken});
        // console.log('watch',this.listadeEstrategiaPorUsuario);
      }
    },
    idModalEstrategia(n){
      if(n !== null) {
        this.idEstrategiaCreated = n;
        this.backConfirmacion(n);
        this.showAddConfirmaciopnModal(true);
      }
    }
  }
};
</script>

<style lang="scss">
#EstrategiaModal {
  .resultado_input{
    &::placeholder{
      font-size: 12px;
      color: rgb(0 0 0 / 30%);
    }
  }
  .estrategia_tabs {
    .nav-tabs {
      border: none;
      .nav-item {
        flex-grow: 1;
        .nav-link {
          width: 100%;
          border: none;
          border-radius: 0;
          &.active {
            background-color: #f5f6fa !important;
            border-bottom: 2px solid rgb(158 197 254 / 80%);
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
    form {
      .form-control {
        &::placeholder {
          font-size: 13px;
          opacity: 0.5;
        }
      }
    }
  }
  .modal-body {
    min-height: 260px;
    .st-lista-confimacion{
      min-height: 80px;
      &-item{
        opacity: .7;
        transition: opacity ease-in-out .2s;
        &:hover{
          opacity: 1;
        }
        .input_section{
          input{
            padding-top: 3px;
            padding-bottom: 3px;
          }
        }
      }
    }
    .delConfirmacion{
      right: -25px;
      top: 7px;
      z-index: 1;
    }
    .confirmacion_add_modal{
      width: 0px;
      left: -100%;
      opacity: 0;
      visibility: hidden;
      transition:  opacity  ease-in .2s;
      &.active-modal{
        transition:  opacity  ease-out .2s;
        opacity: 1;
        visibility: visible;
        left: 0;
        width: 100%;
      }
    }
  }
}
</style>
