<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4614_5039"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.0117188" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4614_5039)">
      <path
        d="M17.75 20.2617C16.7 20.2617 15.8125 19.8992 15.0875 19.1742C14.3625 18.4492 14 17.5617 14 16.5117C14 15.4617 14.3625 14.5742 15.0875 13.8492C15.8125 13.1242 16.7 12.7617 17.75 12.7617C18.8 12.7617 19.6875 13.1242 20.4125 13.8492C21.1375 14.5742 21.5 15.4617 21.5 16.5117C21.5 17.5617 21.1375 18.4492 20.4125 19.1742C19.6875 19.8992 18.8 20.2617 17.75 20.2617ZM17.75 18.2617C18.2333 18.2617 18.6458 18.0909 18.9875 17.7492C19.3292 17.4076 19.5 16.9951 19.5 16.5117C19.5 16.0284 19.3292 15.6159 18.9875 15.2742C18.6458 14.9326 18.2333 14.7617 17.75 14.7617C17.2667 14.7617 16.8542 14.9326 16.5125 15.2742C16.1708 15.6159 16 16.0284 16 16.5117C16 16.9951 16.1708 17.4076 16.5125 17.7492C16.8542 18.0909 17.2667 18.2617 17.75 18.2617ZM4 17.5117V15.5117H12V17.5117H4ZM6.25 11.2617C5.2 11.2617 4.3125 10.8992 3.5875 10.1742C2.8625 9.44922 2.5 8.56172 2.5 7.51172C2.5 6.46172 2.8625 5.57422 3.5875 4.84922C4.3125 4.12422 5.2 3.76172 6.25 3.76172C7.3 3.76172 8.1875 4.12422 8.9125 4.84922C9.6375 5.57422 10 6.46172 10 7.51172C10 8.56172 9.6375 9.44922 8.9125 10.1742C8.1875 10.8992 7.3 11.2617 6.25 11.2617ZM6.25 9.26172C6.73333 9.26172 7.14583 9.09089 7.4875 8.74922C7.82917 8.40755 8 7.99505 8 7.51172C8 7.02839 7.82917 6.61589 7.4875 6.27422C7.14583 5.93255 6.73333 5.76172 6.25 5.76172C5.76667 5.76172 5.35417 5.93255 5.0125 6.27422C4.67083 6.61589 4.5 7.02839 4.5 7.51172C4.5 7.99505 4.67083 8.40755 5.0125 8.74922C5.35417 9.09089 5.76667 9.26172 6.25 9.26172ZM12 8.51172V6.51172H20V8.51172H12Z"
        
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconRoles",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#4613E5" },
  },
};
</script>
