const state = {
    token: '',
    listadeActivosPorUsuario: [],
    listadeEstrategiaPorUsuario: [],
    listadeConfimacionByEstrategiaID: [],
    addConfirmacion: false,
    idModalEstrategia: null,
};

export default state;
