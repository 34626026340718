<template>
  <div
    class="modal fade"
    id="tradingModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropPermissionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog trading_modal modal-xl modal-dialog-centered">
      <div class="modal-content bg-transparent border-0">
        <!-- <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropPermissionLabel">Add Permission</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div> -->
        <div class="modal-body p-0">
          <button
            type="button"
            class="border-0 bg_transparent p-0 position-absolute close_modal"
            data-bs-dismiss="modal"
            ref="closeModalTrading"
          >
            <i class="fa-solid fa-circle-xmark text-white fs-2"></i>
          </button>
          <div class="d-flex inner_modal">
            <!-- <div
              class="col-3 d-md-block d-none bg-primary bg_lft rounded-top-2 rounded-start-2"
            ></div> -->
            <div class="col pb-4" :class="{'bg-white': showConfirmaciones}">
              <div  v-if="showMenu"
                class="py-5 d-flex flex-column align-items-center"
              >
                <div class="d-flex flex-column mb-3">
                  <h2 class="fs-4 fw-bold text-white">
                    Agrega trades a tu Bitácora
                  </h2>
                </div>
                <div class="px-5 w-50 d-flex justify-content-evenly">
                  <div class="px-2">
                    <div
                      class="card_item card rounded-2 py-4 d-flex flex-column align-items-center justify-content-center"
                      @click="showForm = true; showMenu = false"
                    >
                      <icon-engrane class="prev_"></icon-engrane>
                      <icon-engrane class="netx_" :fill="'#FFFFFF'"></icon-engrane>
                      <span class="fs-5 mt-3">Manualmente</span>
                    </div>
                  </div>
                  <div class="px-2">
                    <div
                      class="card_item card rounded-2 py-4 d-flex flex-column align-items-center justify-content-center"
                    >
                      <icon-play class="prev_"></icon-play>
                      <icon-play class="netx_" :fill="'#FFFFFF'"></icon-play>
                      <span class="fs-5 mt-3">Automático</span>
                    </div>
                  </div>
                  <div class="px-2">
                    <div
                      class="card_item card rounded-2 py-4 d-flex flex-column align-items-center justify-content-center"
                    >
                      <icon-upload class="prev_"></icon-upload>
                      <icon-upload class="netx_" :fill="'#FFFFFF'"></icon-upload>
                      <span class="fs-5 mt-3">Importar</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showForm" class="px-5 pt-3 pb-3 d-flex flex-column align-items-center bg-white col-8 mx-auto">
                <div class="text-center mb-3">
                  <span class="text-dark fs-4 fw-bold"
                    >Agrega Trade</span
                  >
                </div>
                  <div class="form_body w-100">
                    <div class="d-flex mb-4">
                      <div
                        class="col-6 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold">Activo</span>
                        <div
                          class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                        >
                          <div class="ico_input">
                            <icon-dollar-circle></icon-dollar-circle>
                          </div>
                          
                          <div class="input_st w-100 d-flex align-items-center">
                            <select
                              class="form-select border-0 fs-7 d-block"
                              aria-label="Default select example"
                              v-model="tradeData.activo"
                            >
                              <option value="0" selected>-- Activo ---</option>
                              <option :value="item.idSeguimientoPar" v-for="(item, index) in listadeActivosPorUsuario" :key="index">{{ item.activo.nombre }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-6 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold">Tipo Operacion</span>
                        <div
                          class="inputs_form border w-100 rounded-1 d-flex align-items-center px-2"
                        >
                          <div class="ico_input">
                            <icon-dollar-arrow></icon-dollar-arrow>
                          </div>
                          
                          <div class="input_st w-100 d-flex align-items-center">
                            <select
                              class="form-select border-0 fs-7"
                              aria-label="Default select example"
                              v-model="tradeData.tipoOperacion"
                            >
                              <option value="0" selected>-- Tipo Operación --</option>
                              <option :value="tipo.idTipoOperacion" v-for="(tipo,index) in listaTipodeOperacion" :key="index">{{ tipo.nombre }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="d-flex mb-4">
                      <div
                        class="col-6 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold"
                          >Fecha de Apertura</span
                        >
                        <div
                          class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                        >
                          <div class="ico_input">
                            <icon-calendar></icon-calendar>
                          </div>
                          
                          <div class="input_st w-100 d-flex align-items-center">
                            <VueDatePicker
                              v-model="tradeData.fechaApertura"
                              class="input_date"
                              :dateFormat="customDateFormat"
                            >
                              <template #input-icon></template>
                            </VueDatePicker>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-6 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold"
                          >Precio de Apertura</span
                        >
                        <div
                          class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2 w-100"
                        >
                          <div class="ico_input">
                            <icon-price></icon-price>
                          </div>
                          
                          <div class="input_st w-100 d-flex align-items-center">
                            <input type="number" v-model="tradeData.precioApertura" class="border-0 fs-7 w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex mb-4">
                      <div
                        class="col-6 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold"
                          >Fecha de Cierre</span
                        >
                        <div
                          class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                        >
                          <div class="ico_input">
                            <icon-calendar></icon-calendar>
                          </div>
                          
                          <div class="input_st w-100 d-flex align-items-center">
                            <VueDatePicker
                              v-model="tradeData.fechaCierre"
                              class="input_date"
                              :dateFormat="customDateFormat"
                            >
                              <template #input-icon></template>
                            </VueDatePicker>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-6 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold"
                          >Precio de Cierre</span
                        >
                        <div
                          class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                        >
                          <div class="ico_input">
                            <icon-price></icon-price>
                          </div>
                          
                          <div class="input_st w-100 d-flex align-items-center">
                            <input type="number" v-model="tradeData.precioCierre" class="border-0 fs-7 w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="d-flex mb-4">
                      <div
                        class="col-4 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold"
                          >Precio stop loss</span
                        >
                        <div
                          class="inputs_form border rounded-1 d-flex align-items-center px-2 w-100"
                        >
                          <div class="ico_input">
                           <icon-dollar></icon-dollar>
                          </div>
                          
                          <div class="input_st d-flex align-items-center">
                            <input type="number" class="border-0 fs-7 w-100" v-model="tradeData.precioStopLoss"/>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-4 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold"
                          >Beneficio Bruto</span
                        >
                        <div
                          class="inputs_form border rounded-1 d-flex align-items-center px-2 w-100"
                        >
                          <div class="ico_input">
                           <icon-dollar></icon-dollar>
                          </div>
                          
                          <div class="input_st d-flex align-items-center">
                            <input type="number" class="border-0 fs-7 w-100" v-model="tradeData.beneficioBruto" />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-4 px-1 d-flex flex-column align-items-start"
                      >
                        <span class="fs-6 text-dark mb-1 fs-semi-bold"
                          >Spread</span
                        >
                        <div
                          class="inputs_form border rounded-1 d-flex align-items-center px-2 w-100"
                        >
                          <div class="ico_input">
                           <icon-dollar></icon-dollar>
                          </div>
                          
                          <div class="input_st d-flex align-items-center w-100">
                            <input type="number" class="border-0 fs-7 w-100" v-model="tradeData.spread"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="col-12 px-1">
                        <div>
                          <div class="d-flex flex-column align-items-start">
                            <span class="fs-6 text-dark mb-1 fs-semi-bold"
                              >Estrategia</span
                            >
                            <div
                              class="inputs_form w-100 border rounded-1 d-flex align-items-center px-2"
                            >
                              <div class="ico_input">
                                <icon-estrategia></icon-estrategia>
                              </div>
                              
                              <div class="input_st w-100 d-flex align-items-center">
                                <select
                                  class="form-select border-0 fs-8"
                                  aria-label="Default select example"
                                  v-model="tradeData.estrategia"
                                >
                                  <option value="0" selected>
                                    -- Estrategia --
                                  </option>
                                  <option :value="item.idEstrategia" v-for="(item, index) in listadeEstrategiaPorUsuario" :key="index">{{ item.nombre }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="text-end mt-4 w-100">
                  <button
                    class="btn btn-primary bg_dashboard rounded-1 px-5 border me-3"
                    data-bs-dismiss="modal"
                  >
                    <span class="fs-7 border-color-secondary text-primary"
                      >cancelar</span
                    >
                  </button>
                  <button class="btn btn-primary rounded-1 px-4" @click="registrarTrade">
                    <span class="fs-7">Agregar Trade</span>
                  </button>
                </div>
              </div>
              <div v-if="false" class="confirmacion_contenedor px-5 pt-5 pb-3 d-flex justify-content-center">
                <div class="confirmacion_contenedor_item pt-4 d-flex flex-column align-items-center w-100">
                  <div class="d-flex col-10">
                    <div class="form-check text-start">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        class="form-check-label text-primary-emphasis fs-7 fw-bold"
                        for="flexCheckDefault"
                      >
                        Cumplio
                      </label>
                    </div>
                    <div class="sep_line px-2 border border-top-0 border-bottom-0 border-start-0"></div>
                    <div class="info_confirmacion flex-fill d-flex">
                      <div class="col-8 text-start ps-3">
                        <span class="fs-7 fw-bold text-secondary">Confirmación Name</span>
                      </div>
                      <div class="col text-end">
                        <select
                          class="form-select border-0 rounded-5 fs-8 text-primary-emphasis bg-primary-subtle"
                          aria-label="Default select example"
                        >
                          <option value="0" selected>-- Resultado --</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-10 mt-3">
                    <div
                        class="px-1 d-flex flex-column justify-content-strech"
                      >
                        <div class="w-100 h-100 py-5 bg_dashboard rounded-2 d-flex align-items-center justify-content-center border border-primary-subtle border-dashed">
                          <div class="d-flex flex-column w-100 py-4">
                            <i class="fa-solid fa-upload fs-5 text-primary-emphasis"></i>
                            <span class="fs-8 fw-bold mt-2 text-primary-emphasis">Upload image</span>
                          </div>

                        </div>
                      </div>
                  </div>
                  <div class="text-end mt-4 col-10">
                  <button class="btn btn-primary rounded-5 px-4">
                    <span class="fs-7 fw-bold">Siguiente</span>
                  </button>
                </div>
                </div>
              </div>
              <confirmacionesTrade v-if="showConfirmaciones" :id_trade="tradeAddID"></confirmacionesTrade>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import ConfirmacionesTrade from "@/components/dashboard/bitacora/ConfirmacionesTrade.vue";
import IconEngrane from "@/components/icons/IconEngrane.vue";
import IconPlay from "@/components/icons/IconPlay.vue";
import IconUpload from "@/components/icons/IconUpload.vue";
import IconDollarCircle from "@/components/icons/IconDollarCircle.vue";
import IconDollarArrow from "@/components/icons/IconDollarArrow.vue";
import IconPrice from "@/components/icons/IconPrice.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconEstrategia from "@/components/icons/IconEstrategia.vue";
import IconDollar from "@/components/icons/IconDollar.vue";
export default {
  name: "ModalTrading",
  components: { VueDatePicker, ConfirmacionesTrade, IconEngrane, IconPlay, IconUpload, IconDollarCircle, IconDollarArrow, IconPrice, IconCalendar, IconEstrategia, IconDollar },
  computed: {
    ...mapGetters("auth", ["getToken", "getUserId"]),
    ...mapGetters("watchlist", ["listadeActivosPorUsuario","listadeEstrategiaPorUsuario"]),
    ...mapGetters("bitacora", ["listaTipodeOperacion","listaTrades"]),
  },
  data() {
    return {
      // date: `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`,
      tradeData:{
        activo: 0,
        tipoOperacion: 0,
        fechaApertura: new Date(),
        fechaCierre: new Date(),
        precioApertura: 0,
        precioCierre: 0,
        precioStopLoss: 0,
        beneficioBruto: 0,
        spread: 0,
        estrategia: 0,
        
      },
      tradeAddID: null,
      showMenu: true,
      showForm: false,
      showConfirmaciones: false,
      customDateFormat: "yyyy-MM-dd",
      date: new Date(),
      date1: new Date(),
      time: {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
      },
      time1: {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
      },
    };
  },
 async mounted() {
    await this.listadeActivosPorUsuario
    this.getActivoByUser({userId: this.getUserId, token: this.getToken});
    this.getEstrategiaByUser({userId: this.getUserId, token: this.getToken});
    this.getTipoOperacion(this.getToken);
    const myModalEl = document.getElementById("tradingModal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      this.showForm = false;
      this.showConfirmaciones = false;
      this.showMenu = true;
      this.getTradePorUsuario({userId: this.getUserId, token: this.getToken});
      this.tradeData = {
        activo: 0,
        tipoOperacion: 0,
        fechaApertura: new Date(),
        fechaCierre: new Date(),
        precioApertura: 0,
        precioCierre: 0,
        precioStopLoss: 0,
        beneficioBruto: 0,
        spread: 0,
        estrategia: 0,
        
      }
    });
  },
  methods:{
    ...mapActions('watchlist', ['getActivoByUser','getEstrategiaByUser','getConfirmacionByIdEstrategia']),
    ...mapActions('bitacora', ['getTipoOperacion','getTradePorUsuario']),
    registrarTrade(){
      let data = {
        "usuario": this.getUserId,
        "fechaApertura": this.tradeData.fechaApertura,
        "fechaCierre": this.tradeData.fechaApertura,
        "idSeguimientoPar": this.tradeData.activo,
        "idTipoOperacion": this.tradeData.tipoOperacion,
        "precioApertura": this.tradeData.precioApertura,
        "precioCierre": this.tradeData.precioCierre,
        "precioSl": this.tradeData.precioStopLoss,
        "precioTp": 0,
        "riesgo": 0.25,
        "spread": this.tradeData.spread,
        "beneficioBruto": this.tradeData.beneficioBruto,
        "balanceInicial": 100000,
        "idEstrategia": this.tradeData.estrategia
      }
      const headers = {
        'Authorization': `Bearer ${this.getToken}`,
        'Content-Type': 'application/json'
      };
      axios.post('https://api.es2trading.com/api/trade/', data, { headers })
      .then(response => {
        this.tradeAddID = response.data.idTrade;
        
        this.tradeData = {
          activo: 0,
          tipoOperacion: 0,
          fechaApertura: new Date(),
          fechaCierre: new Date(),
          precioApertura: 0,
          precioCierre: 0,
          precioStopLoss: 0,
          beneficioBruto: 0,
          spread: 0,
          estrategia: 0,
          
        }
        this.showConfirmaciones = true;
        this.showForm = false;
      })
      .catch(error => {
      // Manejar el error aquí
        console.error(error);
      });
      setTimeout(() => {
        
        // console.log('luego agrego trade', this.listaTrades);
        
      }, 200);
    }
  }
};
</script>

<style lang="scss">
.close_modal {
  top: -10px;
  right: -7px;
  z-index: 999;
}
.trading_modal {
  .inner_modal {
    min-height: 400px;
    .bg_lft {
      background-image: url("@/assets/images/Trading-1.jpg");
      background-position: 50% 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .card_item {
      cursor: pointer;
      border-color: rgb(204, 204, 204, 0.1);
      background-color: #fff;
      transition: box-shadow, background-color ease-in-out 0.2s;
      box-shadow: none;
      height: 172px;
      width: 172px;
      svg.prev_{
        // fill: #512ACD;
        display: inline-block
      }
      svg.netx_{
        display: none
      }
      &:hover {
        span{
          color: #ffffff;
        }
        svg.prev_{
          // fill: #512ACD;
          display: none;
        }
        svg.netx_{
          display: inline-block;
        }
        transition: box-shadow, background-color ease-in-out 0.2s;
        background-color: #512ACD;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        .line_sep {
          transition: padding ease-in-out 0.2s;
          padding: 10px 0;
        }
        .ico_base {
          transition: background-color ease-in-out 0.2s;
          background-color: #0d6efd !important;
          i {
            color: #fff;
          }
        }
        .card_text {
          span {
            transition: font-size ease-in-out 0.2s;
            font-size: 1.1em;
            color: #6c757d;
          }
        }
      }
    }
    .line_sep {
      transition: padding ease-in-out 0.2s;
      padding: 15px 0;
    }
    .ico_base {
      transition: background-color ease-in-out 0.2s;
      height: 80px;
      width: 80px;
      i {
        color: #052c65;
        font-size: 30px;
      }
    }
    .card_text {
      transition: font-size ease-out 0.2s;
      span {
        color: #052c65;
        font-size: 1em;
      }
    }
  }
  .inputs_form {
    .line_divider {
      width: 2px;
      height: 20px;
      border-right: 1px solid #dee2e6;
    }
    .input_st {
      height: 35px;
    }
  }
  .input_date {
    .dp__input {
      border: none;
      padding-left: 12px;
      padding-bottom: 0;
    }
  }
}
</style>
