<template>
  <nav class="nav_theme bg-white p-0 d-flex justify-content-between align-items-center">
    <div class="module_name ps-4">
      
    </div>
    <div class="userBox pe-4 d-flex align-items-center">
      <div class="notifications me-3">
        <button class="bg_transparent border-0 p-0">
          <icon-bell :fill="(themeDark) ? '#ffffff' : '#5F617A'"></icon-bell>
        </button>
      </div>
      <div class="btn-group">
        <button
          class="user_btn btn border dropdown-toggle d-flex justify-content-center align-items-center rounded-circle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
        <span>
          <icon-user :fill="(themeDark) ? '#ffffff' : '#5F617A'"></icon-user>
        </span>
        </button>
        <ul class="dropdown-menu pt-0 pb-0">
          <!-- <li><span class="user_name fs-7 text-secondary-emphasis fw-bold">User Name</span></li>
          <li><span class="user_info fs-7 text-secondary fw-bold">User Work</span></li>
          <li><hr class="dropdown-divider"></li> -->
          <li><span class="dropdown-item fs-7" href="#" data-bs-toggle="modal" data-bs-target="#profileModal">Profile</span></li>
          <li><span class="dropdown-item fs-7" href="#">Settings</span></li>
          <li><span class="dropdown-item fs-7 pb-2 pt-0" data-bs-toggle="modal" data-bs-target="#changePasswordModal">Change Password</span></li>
        </ul>
      </div>
    </div>
    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
  </nav>
  <modal-change-password/>
  <modal-profile/>
</template>

<script>
import IconUser from "@/components/icons/IconUser.vue";
import IconBell from "@/components/icons/IconBell.vue";
import ModalChangePassword from "@/components/modales/ModalChangePassword.vue";
import ModalProfile from "@/components/modales/ModalProfile.vue";
import { mapGetters } from "vuex";
export default {
  name: "Navbar",
  components:{IconUser,IconBell,ModalChangePassword, ModalProfile},
  computed: {
    ...mapGetters(['themeDark']),
  },
};
</script>

<style lang="scss">
nav {
  height: 60px;
  .nav-line{
   height: 25px;
   width: 1px;
   background-color: #dee2e6;
  }
  .user_btn{
    height: 40px;
    width: 40px;
    span{
      position: relative;
      top: -3px;
    }
  }
  .searchBox{
    width: 200px;
    .input-group{
      input{
        height: 35px;
        border-right: 1px;
        border-radius: 3px;
        &::placeholder {
          opacity: .5;
          font-size: 0.85em;
        }
      }
      .input-group-text{
        border-radius: 3px;
        background-color: transparent;
        border-left: 1px;
      }
    }
  }
  .dropdown-toggle{
    padding: 0;
    border: none;
    &::after{
      display: none;
    }
  }
}
</style>
