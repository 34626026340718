import axios from "axios";
const apiGet = {
  async getActivoByUser(data) {
    return axios.get(
      `https://api.es2trading.com/api/seguimientoPar/${data.userId}`,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  },
  async getEstrategiaByUser(data) {
    return axios.get(
      `https://api.es2trading.com/api/estrategia/usuario/${data.userId}`,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  },
  async getConfirmacionByIdEstrategia(data) {
    // console.log('api', data);
    return axios.get(
      `https://api.es2trading.com/api/confirmacionesestrategia/estrategia/${data.estrategiaId}`,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  },
  async getResultadosByConfirmacionID(data) {
    console.log('getResultadosByConfirmacionID', data);
    return axios.get(
      `https://api.es2trading.com/api/resultadoConfirmacion/confirmacionEstrategia/${data.confirmacionId}`,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  },
};
export default apiGet;
