const mutations = {
    SET_TOKEN(state, value) {
        state.token = value
    },
    SET_USERID(state, value) {
        state.userId = value
    },
    SET_USERNAME(state, value) {
        state.userName = value
    },
    SET_USERLASTNAME(state, value) {
        state.userLastName = value
    },
    SET_USEREMAIL(state, value) {
        state.userEmail = value
    },
};

export default mutations;