<template>
  <!--Logo start-->
  <div class="logo-main" :style="{ opacity: opacity }">
    <svg
      :width="width"
      viewBox="0 0 132 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0884761 19.841V1.78516H11.9426V4.73875H3.76384V9.16789H11.2212V12.041H3.76384V16.8925H12.3668V19.8461H0.0859375L0.0884761 19.841Z"
        :fill="fill"
      />
      <path
        d="M23.9286 19.4437C22.8644 20.0495 21.5842 20.2078 19.7986 20.2078C18.5997 20.2078 17.2408 20.155 15.6152 19.9715V17.3875C17.0275 17.571 18.4118 17.6765 19.3973 17.6765C20.2228 17.6765 20.9695 17.6237 21.5817 17.4402C22.2751 17.204 22.4884 16.7012 22.4884 16.1482C22.4884 15.384 22.0617 14.9894 21.2108 14.7255C20.5987 14.5143 20.012 14.4087 19.1331 14.1976C16.7354 13.5918 15.5898 12.5888 15.5898 10.3214C15.5898 8.95148 16.0953 7.60665 17.3475 6.89528C18.3864 6.28948 19.6106 6.15625 21.2896 6.15625C22.3284 6.15625 23.6594 6.23418 25.0716 6.39254V8.87105C23.9007 8.74033 22.5672 8.60711 21.5817 8.60711C20.8349 8.60711 20.2507 8.65989 19.7173 8.81826C19.0518 9.05454 18.8639 9.52963 18.8639 10.0299C18.8639 10.7161 19.2373 11.058 20.0348 11.3219C20.5936 11.5054 21.2337 11.6386 21.9525 11.8221C24.4823 12.3751 25.7625 13.456 25.7625 15.8818C25.7625 17.3045 25.2291 18.7021 23.9235 19.4411L23.9286 19.4437Z"
        :fill="fill"
      />
      <path
        d="M35.2527 4.39362C35.2527 4.78073 35.1892 5.13265 35.0673 5.44686C34.9428 5.76359 34.7777 6.05769 34.5745 6.32917C34.3713 6.60065 34.1351 6.85705 33.8684 7.09333C33.6017 7.32962 33.335 7.5634 33.0683 7.79214C32.766 8.03848 32.4561 8.31499 32.1361 8.62166C31.8161 8.92833 31.5595 9.2149 31.364 9.47883H35.5194V11.8769H27.874C27.8562 11.7361 27.8486 11.6079 27.8486 11.4948V11.1379C27.8486 10.2782 28.0848 9.49894 28.5547 8.80516C29.0246 8.11138 29.713 7.38492 30.6197 6.63081C31.0998 6.22611 31.4859 5.87419 31.778 5.57757C32.0701 5.27844 32.2174 4.97177 32.2174 4.65505C32.2174 4.39111 32.1386 4.17996 31.9786 4.02159C31.8186 3.86323 31.5875 3.78531 31.2852 3.78531C30.7696 3.78531 30.2946 3.88585 29.8603 4.08946C29.4259 4.29056 29.0576 4.51679 28.7554 4.76062L27.3711 2.70442C27.8334 2.31731 28.4226 1.96288 29.1415 1.6361C29.8603 1.31183 30.6197 1.14844 31.4198 1.14844C32.1132 1.14844 32.7025 1.22636 33.1902 1.38473C33.6779 1.54309 34.0741 1.76681 34.3764 2.05588C34.6786 2.34496 34.8996 2.68933 35.0419 3.08398C35.1841 3.47864 35.2552 3.91351 35.2552 4.3886L35.2527 4.39362Z"
        :fill="fill"
      />
      <path
        d="M43.1623 19.8397V4.73485H37.1426V1.78125H52.86V4.73485H46.8402V19.8397H43.1649H43.1623Z"
        :fill="fill"
      />
      <path
        d="M53.1836 20.1092V6.79669H56.1147L56.2214 7.64129C58.1137 6.90226 59.3659 6.42969 61.1236 6.42969C61.2023 6.42969 61.3623 6.42969 61.497 6.45482V9.40842H60.6181C59.3405 9.40842 58.2204 9.61957 56.6481 10.1726V20.1117H53.1861L53.1836 20.1092Z"
        :fill="fill"
      />
      <path
        d="M71.4798 19.8397L71.4011 19.1283C69.6434 19.709 68.1778 20.2092 66.4735 20.2092C65.328 20.2092 64.157 19.9201 63.3036 19.1283C62.4248 18.3365 61.998 17.3612 61.998 15.9661C61.998 14.571 62.5848 13.1457 63.9437 12.4343C64.9825 11.8813 66.0493 11.7758 67.487 11.7758C68.4191 11.7758 69.7501 11.8537 70.949 11.9593V11.5118C70.949 9.5084 70.0955 8.71659 67.2736 8.71659C66.1027 8.71659 64.7971 8.79451 63.5703 8.92774V6.39644C65.0079 6.26573 66.4735 6.16016 67.9111 6.16016C69.8568 6.16016 71.7465 6.37131 72.9733 7.42455C74.2509 8.50544 74.411 9.98098 74.411 11.8537V19.8422H71.4798V19.8397ZM70.9464 14.1462C70.0396 14.0406 68.8687 13.9878 68.0686 13.9878C66.0696 13.9878 65.3508 14.674 65.3508 15.8605C65.3508 17.047 66.123 17.786 67.5352 17.786C68.5995 17.786 69.7983 17.4693 70.9439 17.1274V14.1487L70.9464 14.1462Z"
        :fill="fill"
      />
      <path
        d="M87.8073 19.8397L87.7285 19.1283C86.2096 19.6286 84.5332 20.2092 82.961 20.2092C81.3887 20.2092 80.1644 19.7341 79.2043 18.7865C77.9521 17.5221 77.5 15.6494 77.5 13.1985C77.5 10.5893 78.4068 8.55822 79.7911 7.45219C80.9366 6.5548 82.3742 6.16015 84.2386 6.16015C85.2495 6.16015 86.3417 6.3713 87.2764 6.66038V0.914062H90.7384V19.8422H87.8073V19.8397ZM87.2764 16.6498V9.29473C86.5043 8.95287 85.5975 8.79451 84.7999 8.79451C82.3488 8.79451 81.0433 10.0866 81.0433 13.0904C81.0433 16.0943 82.1888 17.3612 84.1344 17.3612C85.0946 17.3612 86.2401 17.0193 87.279 16.6498H87.2764Z"
        :fill="fill"
      />
      <path
        d="M95.1875 3.99837V0.914062H98.9162V3.99837H95.1875ZM98.7029 19.8397H95.3196V6.52715H98.7816V19.8397H98.7029Z"
        :fill="fill"
      />
      <path
        d="M112.875 19.8397V11.6677C112.875 9.68939 111.783 9.00566 110.157 9.00566C109.197 9.00566 108.133 9.2696 106.827 9.74469V19.8422H103.365V6.52967H106.296L106.375 7.24105C108.079 6.66038 109.73 6.16016 111.356 6.16016C112.794 6.16016 114.234 6.52967 115.219 7.66335C116.126 8.66631 116.337 9.87791 116.337 11.459V19.8422H112.875V19.8397Z"
        :fill="fill"
      />
      <path
        d="M128.537 18.4974C127.232 18.9448 125.82 19.3671 124.514 19.3671C123.048 19.3671 121.877 18.9448 120.999 18.0223C119.8 16.8358 119.348 15.0964 119.348 12.7762C119.348 10.3254 120.173 8.42751 121.504 7.37176C122.622 6.52716 123.955 6.16016 125.713 6.16016C126.99 6.16016 128.296 6.47688 129.416 6.97711L129.495 6.52967H131.999V18.8946C131.999 20.8452 131.679 22.3735 130.534 23.5072C129.175 24.852 126.884 25.0883 124.727 25.0883C123.315 25.0883 121.85 25.0104 120.359 24.8772V22.2152C121.557 22.3735 122.995 22.4263 124.115 22.4263C125.741 22.4263 126.94 22.3484 127.712 21.7149C128.484 21.0563 128.537 20.0006 128.537 19.0001V18.4999V18.4974ZM128.537 9.2696C127.819 8.98053 127.018 8.79451 126.3 8.79451C124.036 8.79451 122.891 9.95333 122.891 12.6958C122.891 15.4382 123.876 16.5442 125.687 16.5442C126.541 16.5442 127.58 16.2275 128.537 15.8857V9.2696Z"
        :fill="fill"
      />
    </svg>
  </div>
  <!--Logo End-->
</template>

<script>
export default {
  name: "LogoName",
  props: {
    color: { type: Boolean, default: false },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    opacity: { type: String, default: "1" },
    fill: { type: String, default: "#000000" },
  },
};
</script>
